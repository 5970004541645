type ('a, 'b) t = Refl : ('a, 'a) t

let cast : type a b. (a, b) t -> a -> b = function Refl -> fun x -> x

let cast_opt : type a b. a -> (a, b) t Prelude.optional -> b Prelude.optional
    =
 fun x -> function Error e -> Error e | Ok Refl -> Ok x

let cast_opt' : type a b. a -> (a, b) t option -> b Prelude.optional =
 fun x -> function None -> Error "" | Some Refl -> Ok x
