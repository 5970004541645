module Make (M : Basic.Model) = struct
  open M
  module Basic = Basic.Make (M)
  open Basic
  module HDocument = HDocument.Make (M)
  module Sign = Sign.Make (M)
  open Html
  open Core
  open LinearA

  let show_ty_short : type a. a Ty.t -> a -> string = Ty.Methods.show

  let show_ty_long : type a. a Ty.t -> a Search.Settings.t -> a -> _ elt list
      =
   fun ty settings ->
    let module Matcher = Ty.Matcher (struct
      type 'a t = 'a -> Html_types.div_content elt list
    end) in
    match
      Matcher.(
        run
          [ on LinearA.Document.ty ~return:(fun x -> HDocument.miniature x)
          ; on LinearA.Document.Sequence.ty ~return:(fun x ->
                HDocument.miniature_with_word x)
          ; on LinearA.Document.Attestation.ty ~return:(fun x ->
                match settings with
                | Attestation {whole = true} ->
                    HDocument.miniature_with_occurrence x
                | _ -> [Sign.Attestation.miniature x]) ])
        ty
    with
    | Ok v -> v
    | Error _ -> fun _ -> []

  let display n title content =
    if n = 0 then
      fieldset ~a:[a_class ["result1"]] ~legend:(legend title) content
    else fieldset ~a:[a_class ["result"]] ~legend:(legend title) content

  let rec show_aux n db rtyp settings = function
    | Grouping.Group (name, typ, groups) ->
        let (U {friendly; _}) = Builtins.find_by_name_untyped name in
        List.map
          (fun (key, l) ->
            display n
              [ fmt "%s: %s [%d results]" friendly (show_ty_short typ key)
                  (Grouping.length l) ]
              (show_aux (n + 1) db rtyp settings l))
          groups
    | Grouping.List l ->
        List.concat @@ List.map (show_ty_long rtyp settings) l

  let show_search db f search =
    let open LinearA.Search in
    let g = Search.exec db search in
    f g @ show_aux 0 db search.typ search.settings g
end
