open Core

type t = Here | Not_here | Unsure [@@deriving yojson]

type bounds = t * t [@@deriving yojson]

let show_before = function Here -> "" | Not_here -> "]-" | Unsure -> "]"

let show_after = function Here -> "" | Not_here -> "-[" | Unsure -> "["

let show (a, b) s = show_before a ^ s ^ show_after b

let parse (p : 'a Parsing.t) : ('a * bounds) Parsing.t =
  let open Parsing in
  let go x =
    let* v = p in
    prefix
      [ ("-[", return (v, (x, Not_here)))
      ; ("[", return (v, (x, Unsure)))
      ; ("", return (v, (x, Here))) ]
  in
  prefix [("]-", go Not_here); ("]", go Unsure); ("", go Here)]
