open Core
open Prelude

module Settings = struct
  type 'a t =
    | Attestation : {whole: bool} -> Document.Attestation.t t
    | Default : 'a t

  let show : type a. a t -> string = function
    | Default -> ""
    | Attestation {whole} -> Conv.bool.Conv.show whole

  let parse : type a. a Ty.t -> a t Parsing.t =
    let open Parsing in
    fun typ ->
      match Ty.test typ Document.Attestation.ty with
      | Ok Refl ->
          Conv.bool.Conv.parse
          >>| (fun whole -> Attestation {whole})
          <|> (string "" >> return Default)
      | _ -> string "" >> return Default

  let conv (type a) (ty : a Ty.t) : a t Conv.t =
    Conv.make ~show ~parse:(parse ty)
end

type 'a t =
  { typ: 'a Ty.t
  ; criteria: ('a -> bool) Term.t list
  ; settings: 'a Settings.t
  ; grouping: (string * Grouping.kind) list }

type _t = Search : 'a t -> _t

let objects_of_ty ty =
  let module M = Ty.Matcher (struct
    type 'a t = Database.t -> ('a -> bool) -> 'a list
  end) in
  M.(
    run_def
      [ on Document.ty ~return:(fun db f ->
            List.filter f (Database.documents db) )
      ; on Document.Sequence.ty ~return:Database.sequences_such_that
      ; on Document.Attestation.ty ~return:Database.attestations_such_that ])
    ~default:(fun _ _ -> [])
    ty

let make_group typ (name, gkind) =
  let open Builtins in
  match List.find (fun (B b) -> b.name = name) (of_typ typ) with
  | B b ->
      let* param = Ty.(cast unit b.param ()) in
      return @@ Grouping.D (b.name, b.ret, b.eval param, gkind)

let make_groups typ list =
  sequence_only_successful @@ List.map (make_group typ) list

let exec : Database.t -> 'a t -> 'a Grouping.result =
 fun db x ->
  let filter inp =
    List.for_all (fun term -> Term.eval term inp) x.criteria
  in
  let objects = objects_of_ty x.typ db filter in
  Grouping.group_by
    (make_groups x.typ x.grouping)
    (List.sort (Ty.Methods.compare x.typ) objects)

let parse =
  let open Parsing in
  let* (Ty.Untyped.T typ) = Ty.Untyped.conv.parse in
  let* () = keyword ":" in
  let* criteria =
    Parsing.sep_by (keyword "&&") (Term.conv Ty.(typ --> bool)).parse
  in
  let* () = keyword "/" in
  let* settings = Settings.parse typ in
  let* () = keyword "/" in
  let* grouping = Grouping.conv.parse in
  return (Search {typ; criteria; grouping; settings})

let show (Search {typ; criteria; settings; grouping}) =
  Printf.sprintf "%s:%s/%s/%s" (Ty.show typ)
    (String.concat " && " @@ List.map Term.show criteria)
    (Settings.show settings)
    (Grouping.conv.show grouping)

let conv = Conv.make ~show ~parse

let pattern_of_instance instance =
  (Some (Script.Readable {instance; confidence = true}), false)

let pattern_of_meta sign = pattern_of_instance {Script.sign; variant = None}

let belong bel b l =
  Term.compose b (Term.apply_builtin bel (Term.Const (Ty.List bel.inp, l)))

let sign ?(types = []) ?(location = []) ?(sign = (None, false)) () =
  Search
    { typ = Document.Attestation.ty
    ; grouping =
        [ ("att-site", Grouping.GroupBy (Some (`Result, Descending)))
        ; ("occ-doc-name", Grouping.(SortBy Ascending)) ]
    ; settings = Default
    ; criteria =
        [ belong Document.Location.belongs Document.Attestation.blocation
            location
        ; belong Document.Kind.belongs Document.Attestation.bkind types
        ; Term.apply_builtin Document.Attestation.bmatches
          @@ Term.Const (Script.CompositionPattern.ty, sign) ] }

let document ?(period = []) ?(types = []) ?(location = []) () =
  Search
    { typ = Document.ty
    ; settings = Default
    ; grouping =
        [ ("doc-site", Grouping.GroupBy (Some (`Key, Ascending)))
        ; ("doc-name", Grouping.(SortBy Ascending)) ]
    ; criteria =
        ( [ belong Document.Location.belongs Document.blocation location
          ; belong Document.Kind.belongs Document.bkind types ]
        @
        if period <> [] then
          [belong Document.Period.belongs Document.bperiod period]
        else [] ) }

let word ?(types = []) ?(location = []) ?(exp = []) () =
  Search
    { typ = Document.Sequence.ty
    ; settings = Default
    ; grouping =
        [ ("seq-site", Grouping.GroupBy (Some (`Result, Descending)))
        ; ("seq-doc-name", Grouping.(SortBy Ascending)) ]
    ; criteria =
        [ belong Document.Location.belongs Document.Sequence.blocation
            location
        ; belong Document.Kind.belongs Document.Sequence.bkind types
        ; Term.apply_builtin Document.SequencePattern.bmatches
          @@ Term.Const (Document.SequencePattern.ty, exp) ] }
