open Core
open Prelude

type 'answer question =
  | Hello : Database.t question
  | EditDocumentComment : string * string -> string question
  | EditOccurrenceComment : string * string * int -> string question
  | EditSignComment : string * string -> string question
  | Auth : string -> unit question

type _question = Q : 'answer question -> _question

type 'question _request = {question: 'question; auth: string option}
[@@deriving yojson]

type 'answer request = 'answer question _request

type 'a reply = Ok of 'a | Error of string [@@deriving yojson]

let answer_ty : type a. a question -> a Ty.t = function
  | Hello -> failwith "hello"
  | EditDocumentComment (_, _) -> Ty.string
  | EditOccurrenceComment (_, _, _) -> Ty.string
  | EditSignComment (_, _) -> Ty.string
  | Auth _ -> Ty.unit

module Untyped = struct
  type t =
    | Hello
    | EditDocumentComment of string * string
    | EditOccurrenceComment of string * string * int
    | EditSignComment of string * string
    | Auth of string
  [@@deriving yojson]

  let project : type a. a question -> t = function
    | Hello -> Hello
    | EditDocumentComment (a, b) -> EditDocumentComment (a, b)
    | EditOccurrenceComment (a, b, c) -> EditOccurrenceComment (a, b, c)
    | EditSignComment (a, b) -> EditSignComment (a, b)
    | Auth pw -> Auth pw

  let inject = function
    | Hello -> Q Hello
    | EditDocumentComment (a, b) -> Q (EditDocumentComment (a, b))
    | EditOccurrenceComment (a, b, c) -> Q (EditOccurrenceComment (a, b, c))
    | EditSignComment (a, b) -> Q (EditSignComment (a, b))
    | Auth pw -> Q (Auth pw)
end

(* questions *)
let question_of_yojson s = Untyped.of_yojson s >|= Untyped.inject

let yojson_of_question q = Untyped.to_yojson @@ Untyped.project q

(* replies *)
let request_of_string s =
  _request_of_yojson question_of_yojson @@ Yojson.Safe.from_string s

let string_of_request s =
  Yojson.Safe.to_string @@ _request_to_yojson yojson_of_question s

let reply_of_string (type a) string (q : a question) : a reply optional =
  Prelude.catch_
    (fun s ->
      reply_of_yojson
        (Ty.Methods.of_yojson (answer_ty q))
        (Yojson.Safe.from_string s))
    string

let string_of_reply (type a) (q : a question) (x : a reply) =
  Yojson.Safe.to_string
  @@ reply_to_yojson (Ty.Methods.to_yojson (answer_ty q)) x
