open Core
open Prelude

module SMap = Map.Make (struct
  type t = string

  let compare = compare
end)

module SDoc = struct
  include SMap

  type t = Document.t SMap.t

  let of_list =
    List.fold_left
      (fun m doc -> SMap.add (Document.name doc) doc m)
      SMap.empty

  let to_yojson (e : t) =
    `List (List.map Document.to_yojson (List.map snd (SMap.bindings e)))

  let of_yojson e : (t, _) result =
    Stdlib.Result.map of_list ([%of_yojson: Document.t list] e)
end

type t = {documents: SDoc.t} [@@deriving yojson]

let create ~docs = {documents = SDoc.of_list docs}

let load_from_directory directory =
  let parse_directory dir =
    match
      Document.of_yojson @@ Yojson.Safe.from_file @@ (dir // "meta.json")
    with
    | Ok d -> Ok d
    | Error e -> Error e
    | exception e -> Error (Printexc.to_string e)
  in
  let rec scan_directories file =
    if Sys.file_exists (file // "meta.json") then [parse_directory file]
    else
      let f name =
        if name = "." || name = ".." then []
        else if Sys.is_directory (file // name) then
          scan_directories (file // name)
        else []
      in
      List.concat @@ Array.to_list @@ Array.map f @@ Sys.readdir file
  in
  let docs = sequence_only_successful @@ scan_directories directory in
  return {documents = SDoc.of_list docs}

let save_to_directory directory db =
  SDoc.iter
    (fun _ d ->
      Yojson.Safe.to_file
        (directory // Document.meta_file d)
        (Document.to_yojson d) )
    db.documents

let find_document db name = SDoc.find name db.documents

let documents db =
  List.sort Document.compare @@ List.map snd @@ SDoc.bindings db.documents

let write_file db directory =
  let fd = open_out (directory // "database.js") in
  Printf.fprintf fd "/* Signs */\n%s\n/* Data */\n%s\n"
    (Prelude.js_var "signs" (Script.DB.to_string ()))
    (Prelude.js_var "data" (Marshal.to_string db [])) ;
  close_out fd

let of_string s = Marshal.from_string (Prelude.js_decode_string s) 0
(*let edit_db dir db f = let db' = f db in write_file db' dir ; db'

  let opt (s, s') = if s = "" then None else Some (s, s')*)

let locations db =
  let module S = Set.Make (String) in
  S.elements
  @@ SDoc.fold
       (fun _ doc set -> S.add (Document.location doc) set)
       db.documents S.empty

let periods db =
  let module S = Set.Make (String) in
  S.elements
  @@ SDoc.fold
       (fun _ doc set ->
         match Document.period doc with None -> set | Some s -> S.add s set
         )
       db.documents S.empty

let scribes db =
  let module S = Set.Make (String) in
  S.elements
  @@ SDoc.fold
       (fun _ doc set ->
         match Document.scribe doc with None -> set | Some s -> S.add s set
         )
       db.documents S.empty

let motifs db =
  let module S = Set.Make (String) in
  S.elements
  @@ SDoc.fold
       (fun _ doc set ->
         match (Document.meta doc).impressions with
         | Some {motif = Some s; _} -> S.add s set
         | _ -> set )
       db.documents S.empty

let find_spots db =
  let module S = Set.Make (String) in
  S.elements
  @@ SDoc.fold
       (fun _ doc set ->
         match Document.find_spot doc with
         | None -> set
         | Some s -> S.add s set )
       db.documents S.empty

let sequences_such_that db f =
  List.concat
  @@ List.map (fun (Document.D doc) ->
         List.filter f @@ Array.to_list doc.Document.sequences )
  @@ documents db

let attestations_such_that db f =
  List.concat
  @@ List.map (fun (Document.D doc) ->
         List.filter f @@ Array.to_list doc.Document.signs )
  @@ documents db

let types db =
  let module S = Set.Make (String) in
  S.elements
  @@ SDoc.fold
       (fun _ doc set -> S.add (Document.kind doc) set)
       db.documents S.empty

let parse_occurrence_ s =
  try Scanf.sscanf s "%[^/]/%d" (fun name number -> Some (name, number))
  with _ -> None

let representative_image_sign sign =
  let ( let* ) = Option.bind in
  let* rep = sign.Script.rep in
  let* name, number = parse_occurrence_ rep in
  Some (Document.Attestation.image_ name number)

let representative_image_inst {Script.sign; variant} =
  match variant with
  | None -> representative_image_sign sign
  | Some v ->
      Option.map
        (fun (a, b) -> Document.Attestation.image_ a b)
        (parse_occurrence_ (List.assoc v sign.variants))

let check_instance db ({Script.sign; variant} as inst) =
  let check err s =
    match parse_occurrence_ s with
    | None ->
        Printf.eprintf "ERRROR: %s: Could not parse representative %s\n" err
          s
    | Some (name, num) -> (
      try
        let occ = Document.at (find_document db name) num in
        match occ.Document.reading with
        | Unreadable | Unclassified ->
            Printf.eprintf "ERROR: %s: representative sign is unreadable.\n"
              err
        | Readable x ->
            let inst = x.instance in
            let open Script in
            if not (inst.sign.number = sign.number && inst.variant = variant)
            then
              Printf.eprintf
                "ERROR: %s: representative sign is actually %s ! \n" err
                (Script.Instance.show inst)
      with _ -> () )
  in
  match (sign.Script.rep, variant) with
  | None, None ->
      Printf.eprintf "Warning: %s has no representative\n"
        (Script.Sign.show_full sign)
  | Some rep, None -> check (Script.Sign.show_full sign) rep
  | _, Some variant -> (
    match List.assoc_opt variant sign.variants with
    | Some rep -> check (Script.Instance.show inst) rep
    | None ->
        Printf.eprintf "Warning: no representative for variant %s of %s\n"
          variant
          (Script.Sign.show_full sign) )

let check_representative db =
  let instances = Script.Instance.list () in
  List.iter (check_instance db) instances

module MatchEnum = Ty.Matcher (struct
  type nonrec 'a t = t -> 'a list
end)

let dict = MatchEnum.create ()

let is_enumerable ty =
  match MatchEnum.lookup_opt dict ty with Ok _ -> true | _ -> false

let enumerate db ty =
  match MatchEnum.lookup_opt dict ty with Ok f -> f db | _ -> []

let _ =
  let open Document in
  MatchEnum.add dict Location.ty locations ;
  MatchEnum.add dict Scribe.ty scribes ;
  MatchEnum.add dict Period.ty periods ;
  MatchEnum.add dict Find_spot.ty find_spots

let register_enumerate x y = MatchEnum.add dict y x
