type t = {x: int; y: int; w: int; h: int} [@@deriving yojson]

let same_line _ _ = false

let union r1 r2 =
  let x = min r1.x r2.x and y = min r1.y r2.y in
  { x
  ; y
  ; w = max (r1.x + r1.w) (r2.x + r2.w) - x
  ; h = max (r1.y + r1.h) (r2.y + r2.h) - y }

let lines =
  let rec aux rects current_rect = function
    | [] -> rects @ [current_rect]
    | t :: q when same_line current_rect t ->
        let current_rect = union current_rect t in
        aux rects current_rect q
    | t :: q -> aux (rects @ [current_rect]) t q
  in
  function [] -> [] | t :: q -> aux [] t q

let bounds = lines
