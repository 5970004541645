open Core
open LinearA
open Generation
open Model
open M.Html
open Basic

let depiction_of meta =
  Script.Sign.show meta
  :: (match meta.Script.latin with Some s -> [s] | _ -> [])
  @
  match meta.Script.phonetic with
  | Some s -> [PhoneticValue.show s]
  | _ -> []

let signs_completion include_link =
  List.concat_map
    (fun sign ->
      let do_inst (depiction, variant) =
        let inst = {Script.sign; variant} in
        let s = depiction ^ match variant with None -> "" | Some x -> x in
        let descr =
          lazy
            (fmt "View all attestations of "
             :: Sign.Instance.image inst ~cl:"sign-image-inline" )
        in
        let link =
          Url.search_results
            (Search.sign ~sign:(Search.pattern_of_instance inst) ())
        in
        (s, descr, if include_link then Some link else None)
      in
      let l = depiction_of sign in
      let v = None :: List.map (fun x -> Some (fst x)) sign.variants in
      let prod =
        List.concat (List.map (fun x -> List.map (fun y -> (x, y)) v) l)
      in
      List.map do_inst prod )
    (Script.Sign.list ())

let create _ =
  let dynamic_list =
    Widget.Autocomplete.dynamics
      [ (fun s ->
          if String.contains s '-' then
            match Parsing.run Document.SequencePattern.parse s with
            | Ok exp ->
                [ ( [fmt "Search for word attestations matching ``%s''" s]
                  , Url.search_results @@ Search.word ~exp () ) ]
            | _ -> []
          else [] )
      ; (fun s ->
          if String.contains s '+' || String.contains s '|' then
            match Parsing.run Script.CompositionPattern.parse s with
            | Ok sign ->
                [ ( [fmt "Search for composite sign matching ``%s''" s]
                  , Url.search_results @@ Search.sign ~sign () ) ]
            | _ -> []
          else [] ) ]
  in
  let module S = Set.Make (String) in
  let locations, types, documents =
    let aux (l, t, d) doc =
      ( S.add (Document.location doc) l
      , S.add (Document.kind doc) t
      , ( Document.name doc
        , lazy [txt "View document"]
        , Some (Url.document doc) )
        :: d )
    in
    List.fold_left aux (S.empty, S.empty, [])
      (Database.documents (Socket.db ()))
  in
  let static_list =
    List.map
      (fun loc ->
        ( loc
        , lazy [fmt "View all documents found at %s" loc]
        , Some (Url.location loc) ) )
      (S.elements locations)
    @ List.map
        (fun kind ->
          ( kind
          , lazy [fmt "View all document of type %s" kind]
          , Some (Url.kind kind) ) )
        (S.elements types)
    @ List.rev documents @ signs_completion true
  in
  Widget.Autocomplete.(
    create "Quick-search corpus" (static static_list @@@ dynamic_list))

let setup id = try Model.set_content_id id [fst @@ create ()] with _ -> ()
