(* basic markup *)

type t = ..

type t += String of string

type t += List of t list

type t += Sup of t

type t += Sub of t

type t += Attr of string * t

type t += Img of string * string

let ( +++ ) t u =
  match (t, u) with
  | List t, List u -> List (t @ u)
  | List t, x -> List (t @ [x])
  | x, List t -> List (x :: t)
  | x, y -> List [x; y]

let rec concat sep = function
  | [] -> String ""
  | [x] -> x
  | t :: q -> t +++ sep +++ concat sep q
