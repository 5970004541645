open Core
open Js_of_ocaml
open Model
open M.Html
open Basic

(*let show_note callback content = Edit.show "[ Edit the note ]" content (fun
  s k -> callback s (function Ok s -> k s | _ -> ()))*)

module Autocomplete = struct
  (* Autocompletion *)

  type candidate =
    { display: Html_types.span elt list
    ; action: unit -> unit
    ; query: string option }

  type completion = string -> candidate list

  let rec take n = function
    | [] -> []
    | _ when n = 0 -> []
    | t :: q -> t :: take (n - 1) q

  let create ?(value = "") placeholder completion =
    let input =
      input
        ~a:
          [ a_autofocus ()
          ; a_value value
          ; a_placeholder placeholder
          ; a_input_type `Text ]
        ()
    in
    let content = span [] in
    let input_dom = Model.cast_input input in
    let current_candidates =
      ref (if value = "" then [] else completion value)
    in
    let select t =
      Option.iter (fun s -> input_dom##.value := Js.string s) t.query ;
      t.action ()
    in
    let enter () =
      match !current_candidates with [] -> () | t :: _ -> select t
    in
    let draw_candidate t =
      span
        ~a:
          [a_class ["completion-item"]; a_onclick (fun _ -> select t ; false)]
        t.display
    in
    let draw_candidates () =
      set_content content (List.map draw_candidate !current_candidates)
    in
    let update_completion () =
      current_candidates :=
        take 10 (completion (Js.to_string input_dom##.value)) ;
      draw_candidates ()
    in
    let on_key ev =
      if ev##.keyCode = 13 then (set_content content [] ; enter ())
      else if ev##.keyCode = 27 then set_content content []
      else update_completion () ;
      Js.bool false
    in
    (Model.cast input)##.onkeyup := Dom.handler on_key ;
    ( span ~a:[a_class ["completion-main"]] [input; content]
    , fun () -> Js.to_string input_dom##.value )

  let ( @@@ ) f g x = f x @ g x

  let list = function
    | [] -> fun _ -> []
    | t :: q -> List.fold_left ( @@@ ) t q

  let static candidates prefix =
    let draw (name, (lazy help), link) =
      let suffix = Prelude.trim name (String.length prefix) in
      { query = Some name
      ; action =
          (fun () ->
            Option.iter
              (fun link ->
                Dom_html.window##.location##assign (Js.string link) )
              link )
      ; display =
          [ span
              ~a:[a_class ["completion-item-value"]]
              [b [txt prefix]; fmt "%s" suffix]
          ; span ~a:[a_class ["completion-item-descr"]] help ] }
    in
    let results =
      List.filter
        (fun (name, _, _) -> Prelude.is_prefix prefix name)
        candidates
    in
    List.map draw results

  let dynamic f prefix =
    let draw (text, link) =
      { query = None
      ; action =
          (fun () -> Dom_html.window##.location##assign (Js.string link))
      ; display = [span ~a:[a_class ["completion-item-value"]] text] }
    in
    List.map draw (f prefix)

  let dynamics fs = list (List.map dynamic fs)

  let statics fs = list (List.map static fs)
end
