open Core

(* Exporting document to HTML. *)
module Make (M : Basic.Model) = struct
  (* In the view, the metadata part. *)
  open M
  open LinearA
  open Document
  open Html
  module Basic = Basic.Make (M)
  module Sign = Sign.Make (M)
  open Basic

  let compute_height occ =
    let open Document in
    (occ.position.y + (occ.position.h / 2))
    * 100
    / snd (Document.image_size (Document.Attestation.document occ))

  let top_of_occ occ = Printf.sprintf "top: %d%%" (compute_height occ)

  let count wordview selected document =
    ignore selected ;
    if wordview then
      [fmt "%d signs " (Array.length (signs document))]
      @ link (Url.document ~wordview:false document) "[sign view]"
      @ [fmt " / %d words" (Array.length (sequences document))]
    else
      [ fmt "%d signs / %d words "
          (Array.length (signs document))
          (Array.length (sequences document)) ]
      @ link (Url.document ~wordview:true document) " [word view]"

  let impression {Metadata.number; motif; kind; _} =
    [fmt "Seal impressions: %d" number]
    @ opt (fun x -> [fmt " — Motif: %s" x]) motif
    @ opt
        (fun kind ->
          let search_term =
            Str.global_replace (Str.regexp " \\([0-9][0-9]\\)$") " 0\\1"
            @@ Str.global_replace (Str.regexp "Supp.\\|no.") "" kind
          in
          let search =
            Printf.sprintf "https://arachne.dainst.org/search?q=%s"
              search_term
          in
          [ fmt " ("
          ; txt kind
          ; a
              ~a:[a_href search; a_style "font-size: 0.8em"]
              [txt " (Search in Arachne)"]
          ; fmt ")" ] )
        kind
    @ [br ()]

  let metadata wordview selected document =
    div
      ~a:[a_class ["document-metadata"]]
      ( link_f Url.kind (kind document)
      @ [txt " found at "]
      @ link_f Url.location (location document)
      @ opt (fun s -> [fmt " (%s)" (Core.Size.show s)]) (size document)
      @ [br ()]
      @ opt
          (fun s -> [txt "Period: "] @ link_f Url.period s @ [br ()])
          (period document)
      @ opt
          (fun url -> [a ~a:[a_href url] [txt "Link to corpus"]; txt " — "])
          (url document)
      @ [ a
            ~a:[a_href (Url.document_image document)]
            [txt "Link to tablet drawing\n"] ]
      @ [br ()]
      @ opt impression (Document.meta document).impressions
      @ count wordview selected document )

  let size_of doc zoom =
    match (zoom, Document.size doc) with
    (* | None, _ -> ((100., Some `Percent), (100., Some `Percent))*)
    | Some z, Some sz ->
        ((z *. Size.width sz, Some `Cm), (z *. Size.height sz, Some `Cm))
    | Some z, _ ->
        let w, h = Document.image_size doc in
        let ratio = float h /. float w in
        let default_w = 3.5 in
        ((z *. default_w, Some `Cm), (z *. default_w *. ratio, Some `Cm))
    | _ -> ((100., Some `Percent), (100., Some `Percent))

  let class_of occurrence =
    if occurrence.Document.erasure then ["erasure"]
    else
      match occurrence.Document.role with
      | Role.Syllabogram (_, k), _ ->
          ["syllabogram"; (if k mod 2 = 0 then "even" else "odd")]
      | TransactionSign, _ -> ["transaction"]
      | x, _ -> [Role.show_kind x]

  let rect_sign selected occ =
    let cl = if Some occ.number = selected then ["selected"] else [] in
    let on_hover, on_out =
      match selected with
      | None ->
          ( Some (Dynamic.show (Printf.sprintf "occ-%d" occ.number))
          , Some (Dynamic.hide (Printf.sprintf "occ-%d" occ.number)) )
      | _ -> (None, None)
    in
    Svg.a
      ~a:
        [ Svg.a_href
            (Url.document ~selected:occ.number (Attestation.document occ)) ]
      [rect ?on_out ?on_hover ("sign" :: class_of occ @ cl) occ.position]

  let rect_word selected word =
    let cl = [(if word.index mod 2 = 0 then "even" else "odd"); "word"] in
    let cl = if Some word.index = selected then "selected" :: cl else cl in
    let on_hover, on_out =
      match selected with
      | None ->
          ( Some (Dynamic.show (Printf.sprintf "word-%d" word.index))
          , Some (Dynamic.hide (Printf.sprintf "word-%d" word.index)) )
      | _ -> (None, None)
    in
    let rects =
      List.map (rect ?on_out ?on_hover [])
      @@ Rect.bounds
      @@ List.map (fun o -> o.position) word.items
    in
    Svg.a
      ~a:
        [ Svg.a_class cl
        ; Svg.a_href
            (Url.document ~selected:word.index ~wordview:true
               (Sequence.document word) ) ]
      rects

  let close_button wordview doc =
    a
      ~a:[a_class ["close-button"]; a_href (Url.document ~wordview doc)]
      [txt "x"]

  let popup_sign ?(selected = false) occurrence =
    let is_left = Attestation.is_left occurrence in
    let cl =
      ["popup"; (if is_left then "popup-left" else "popup-right")]
      @ class_of occurrence
      @ if selected then ["selected"] else []
    in
    span
      ~a:
        [ a_style (top_of_occ occurrence)
        ; a_class cl
        ; a_id ("occ-" ^ string_of_int occurrence.number) ]
      [ guard selected @@ close_button false (Attestation.document occurrence)
      ; span
          ~a:[a_class ["info-title"]]
          (fmt "#%d: " occurrence.number
           :: Sign.Attestation.show ~menu:true occurrence )
      ; span
          ~a:[a_class ["role"]]
          [fmt "%s" (Sign.Attestation.function_of occurrence)]
      ; Sign.Attestation.image occurrence ]

  let popup_sequence ?(selected = false) word =
    let is_left = Sequence.is_left word in
    let cl =
      ["word"; "popup"; (if is_left then "popup-left" else "popup-right")]
      @ if selected then ["selected"] else []
    in
    span
      ~a:
        [ a_style (top_of_occ @@ List.hd word.items)
        ; a_class cl
        ; a_id ("word-" ^ string_of_int word.index) ]
      ( [ guard selected @@ close_button false (Sequence.document word)
        ; span
            ~a:[a_class ["info-title"]]
            [fmt "Sequence #%d" (1 + word.index)] ]
      @ Sign.Sequence.show ~menu:true word
      @ [fmt " (%d signs)" (List.length word.items)] )

  let rects wordview selected document =
    if not wordview then map_signs document (rect_sign selected)
    else map_sequences document (rect_word selected)

  let popups wordview selected document =
    match (wordview, selected) with
    | false, None -> map_signs document popup_sign
    | false, Some i -> [popup_sign ~selected:true (Document.at document i)]
    | true, None -> map_sequences document popup_sequence
    | true, Some i -> [popup_sequence ~selected:true (sequences document).(i)]

  let svg ?(include_popups = true) ?zoom wordview selected document =
    let path = Url.ressource @@ Document.image document in
    let width, height = size_of document zoom in
    let px, py = Document.image_size document in
    let r = {Rect.x = 0; y = 0; w = px; h = py} in
    div
      ~a:[a_class ["document-view"]]
      (svg
         ~a:Svg.[a_width width; a_height height; viewBox r]
         (image path r :: rects wordview selected document)
       :: (if include_popups then popups wordview selected document else [])
      )

  let page wordview selected document () =
    page (name document)
      [ metadata wordview selected document
      ; div
          ~a:[a_style "display: block; text-align: center"]
          [svg wordview selected document] ]

  let all document =
    let generate wordview selected =
      ( Url.with_root "" (fun _ -> Url.document ~wordview ?selected document)
      , page wordview selected document )
    in
    generate false None
    ::
    generate true None
    ::
    ( map_signs document (fun x -> generate false (Some x.number))
    @ map_sequences document (fun x -> generate true (Some x.index)) )

  let miniature doc =
    link_ (Url.document doc) ~title:"View document"
      [ Basic.figure
          ~caption:[txt (Document.name doc)]
          [svg ~include_popups:false ~zoom:1. false None doc] ]

  let miniature_with_word wo =
    link_ (Url.sequence wo)
      [ figure
          ~caption:
            ( [fmt "%s" (Document.Sequence.document_name wo); txt ": "]
            @ Sign.Sequence.show wo )
          [ svg ~include_popups:false ~zoom:1. true (Some wo.index)
              (Document.Sequence.document wo) ] ]

  let miniature_with_occurrence occ =
    link_ (Url.occurrence occ)
      [ figure
          ~caption:
            (fmt "%s: " (Document.Attestation.document_name occ)
             :: Sign.Attestation.show occ )
          [ svg ~include_popups:false ~zoom:1. false (Some occ.number)
              (Document.Attestation.document occ) ] ]
end
