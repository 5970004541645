open Core

type kind =
  | Logogram
  | Syllabogram of int * int
  | Fraction
  | TransactionSign
  | Unknown
  | Number
[@@deriving yojson]

type t = kind Uncertainty.t [@@deriving yojson]

let show_kind = function
  | Logogram -> "logogram"
  | Syllabogram (_, _) -> "syllabogram"
  | Fraction -> "fraction"
  | TransactionSign -> "transaction sign"
  | Number -> "number"
  | Unknown -> "unknown"

let show = Uncertainty.show show_kind

let parse_kind =
  let open Parsing in
  prefix
    [ ("transactionsign", return TransactionSign)
    ; ("TransactionSign", return TransactionSign)
    ; ("standalone", return TransactionSign)
    ; ("log", return Logogram)
    ; ("logogram", return Logogram)
    ; ("fraction", return Fraction)
    ; ("number", return Number) ]

let parse = Uncertainty.parse parse_kind

let conv = Conv.make ~show ~parse

let ty = Ty.Custom.add ~conv ~friendly:"sign function" "role"

let _ =
  let f = Builtins.register ~param:Ty.unit ~inp:ty in
  f ~ret:Ty.bool
    ~eval:(fun _ -> function Logogram, _ -> true | _ -> false)
    ~friendly:"Is a logogram" "logogram?" ;
  f ~ret:Ty.bool
    ~eval:(fun _ -> function Fraction, _ -> true | _ -> false)
    ~friendly:"Is a fraction" "fraction?" ;
  f ~ret:Ty.bool
    ~eval:(fun _ -> function Syllabogram (_, _), _ -> true | _ -> false)
    ~friendly:"Is a syllabogram" "syllabogram?" ;
  f ~ret:Ty.bool
    ~eval:(fun _ -> function TransactionSign, _ -> true | _ -> false)
    ~friendly:"Is a transaction sign" "transaction-sign?" ;
  f ~ret:Ty.bool
    ~eval:(fun _ -> function Unknown, _ -> true | _ -> false)
    ~friendly:"Has an unknown function" "role-unknown?" ;
  f ~ret:Ty.bool
    ~eval:(fun _ -> function _, b -> b = Unsure)
    ~friendly:"Has a uncertain role " "role-unsure?"
