open Js_of_ocaml
open Js_of_ocaml_tyxml

module M = struct
  include Tyxml_js

  module Dynamic = struct
    let modify s id : Xml.mouse_event_handler =
     fun _ ->
      try
        (Dom_html.getElementById id)##.style##.display := Js.string s ;
        false
      with _ -> false

    let hide = modify "none"

    let show = modify "block"
  end
end

module Basic = Generation.Basic.Make (M)
module Grouping = Generation.Grouping.Make (M)
module HDocument = Generation.HDocument.Make (M)
module Sign = Generation.Sign.Make (M)
module Home = Generation.Home.Make (M)
module Pages = Generation.Pages.Make (M)

let set_class x cl = x##.className := Js.string cl

let set_content_node node content =
  node##.innerHTML := Js.string "" ;
  if content = [] then node##.style##.visibility := Js.string "hidden"
  else (
    node##.style##.visibility := Js.string "visible" ;
    List.iter (fun child -> Dom.appendChild node child) content )

open Tyxml_js

let cast = To_dom.of_element

let uncast = Of_dom.of_element

let set_content x content = set_content_node (cast x) (List.map cast content)

let set_content_id x content =
  set_content_node (Dom_html.getElementById x) (List.map cast content)

let cast_input = To_dom.of_input

let enclosed ?(cl = "") title content =
  Html.fieldset ~a:[Html.a_class [cl]] ~legend:(Html.legend title) content

let action ?(title = "") ?(cl = []) f text =
  let tit = title in
  Html.(
    a ~a:[a_title tit; a_class cl; a_onclick (fun _ -> f () ; false)] text)

let fix f =
  let rec x = lazy (f x) in
  Lazy.force x

let combobox default on_change groups =
  let open Html in
  let create self =
    let on_change _ =
      let value =
        Js.to_string (Tyxml_js.To_dom.of_select (Lazy.force self))##.value
      in
      List.iter
        (fun (x, l) ->
          List.iter (fun (y, z) -> if y = value then on_change x y z) l )
        groups ;
      false
    in
    let make_option _ (s, v) =
      match default with
      | `Default v' when v = v' ->
          option ~a:[a_selected (); a_value s] (txt s)
      | _ -> option ~a:[a_value s] (txt s)
    in
    let placeholder =
      match default with
      | `Placeholder s ->
          [ option
              ~a:[a_value ""; a_disabled (); a_selected (); a_hidden ()]
              (txt s) ]
      | _ -> []
    in
    let make_group (group_name, list) =
      optgroup ~label:group_name (List.map (make_option group_name) list)
    in
    let groups =
      match groups with
      | [(group_name, list)] -> List.map (make_option group_name) list
      | l -> List.map make_group l
    in
    select ~a:[a_required (); a_onchange on_change] (placeholder @ groups)
  in
  fix create

let help content =
  Html.(
    span
      ~a:[a_class ["help"]]
      [ span ~a:[a_class ["question"]] [txt "?"]
      ; span ~a:[a_class ["help-content"]] content ])
