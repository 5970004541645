type sorting = Ascending | Descending

let show_sorting = function Ascending -> "/\\" | Descending -> "\\/"

let parse_sorting =
  Parsing.(
    string "/\\" >> return Ascending <|> (string "\\/" >> return Descending))

let cmp : type a. a Ty.t -> ('b -> a) -> sorting -> 'b -> 'b -> int =
 fun ty key sorting ->
  match sorting with
  | Descending -> fun x y -> Ty.Methods.compare ty (key y) (key x)
  | Ascending -> fun x y -> Ty.Methods.compare ty (key x) (key y)

type kind =
  | GroupBy of ([`Key | `Result] * sorting) option
  | SortBy of sorting

let show_kind = function
  | GroupBy None -> "group"
  | GroupBy (Some (`Key, sort)) ->
      Printf.sprintf "groupKey%s" (show_sorting sort)
  | GroupBy (Some (`Result, sort)) ->
      Printf.sprintf "groupResult%s" (show_sorting sort)
  | SortBy sorting -> Printf.sprintf "sort%s" (show_sorting sorting)

let parse_kind =
  let open Parsing in
  string "groupKey" >> parse_sorting
  >>| (fun sort -> GroupBy (Some (`Key, sort)))
  <|> ( string "groupResult" >> parse_sorting
      >>| fun sort -> GroupBy (Some (`Result, sort)) )
  <|> (string "sort" >> parse_sorting >>| fun sort -> SortBy sort)
  <|> (string "group" >> return (GroupBy None))

type t = (string * kind) list

let item =
  Conv.make
    ~show:(fun (s, k) -> Printf.sprintf "%s:%s" s (show_kind k))
    ~parse:
      Parsing.(
        take_while1 (( <> ) ':')
        >>= fun s -> keyword ":" >> parse_kind >>= fun k -> return (s, k))

let conv = Conv.list item

type 'a descr = D : string * 'b Ty.t * ('a -> 'b) * kind -> 'a descr

type 'a result =
  | List : 'a list -> 'a result
  | Group : string * 'b Ty.t * ('b * 'a result) list -> 'a result

let rec length : type a. a result -> int = function
  | List l -> List.length l
  | Group (_, _, l) ->
      List.fold_left ( + ) 0 @@ List.map (fun (_, x) -> length x) l

let add ~key elem g =
  let rec aux acc = function
    | (key', list) :: q when key = key' ->
        List.rev acc @ ((key', list @ [elem]) :: q)
    | t :: q -> aux (t :: acc) q
    | [] -> (key, [elem]) :: List.rev acc
  in
  aux [] g

let rec group_by : type a. a descr list -> a list -> a result =
 fun descr list ->
  match descr with
  | [] -> List list
  | D (_, ty, key, SortBy b) :: rest ->
      group_by rest (List.sort (cmp ty key b) list)
  | D (name, ty, key, GroupBy b) :: rest ->
      let go groups elem = add ~key:(key elem) elem groups in
      let groups = List.fold_left go [] list in
      let groups =
        match b with
        | None -> groups
        | Some (which, ascending) ->
            let cmp_fun = function
              | `Key -> cmp ty fst ascending
              | `Result -> cmp Ty.int (fun (_, l) -> List.length l) ascending
            in
            List.sort (cmp_fun which) groups
      in
      Group
        ( name
        , ty
        , List.map (fun (key, list) -> (key, group_by rest list)) groups )

(* | SortBy (key, cmp) :: rest -> group_by rest (List.sort (fun a b ->
   apply_cmp cmp (key a) (key b)) list) | GroupBy (key, cmp, show) :: rest ->
   let go groups elem = add ~key:(key elem) elem groups in let groups =
   List.fold_left go [] list in let groups = match cmp with | Some cmp ->
   List.sort (fun a b -> cmp a b) groups | _ -> groups in Group (List.map
   (fun (a, b) -> (show a b, group_by rest b)) groups)

   let dual_cmp = function None -> None | Some f -> Some (fun a b -> -f a b)

   let dual = function | SortBy (key, cmp) -> SortBy (key, dual_cmp cmp) |
   GroupBy (key, cmp, x) -> GroupBy (key, dual_cmp cmp, x) *)
