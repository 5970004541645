open Core
open Prelude

module Location = struct
  type t = string [@@deriving yojson]

  let ty =
    ( Ty.Custom.add ~friendly:"Archaelogical site" "location"
        ~conv:Conv.string
      : string Ty.t )

  let belongs = Builtins.belongs ty
end

module Motif = struct
  type t = string [@@deriving yojson]

  let ty =
    ( Ty.Custom.add ~friendly:"Motif (seal impressions)" "motif"
        ~conv:Conv.string
      : string Ty.t )

  let belongs = Builtins.belongs ty
end

module Period = struct
  type t = string [@@deriving yojson]

  let ty =
    ( Ty.Custom.add ~friendly:"Period" "period" ~conv:Conv.string
      : string Ty.t )

  let belongs = Builtins.belongs ty
end

module Scribe = struct
  type t = string [@@deriving yojson]

  let ty =
    ( Ty.Custom.add ~friendly:"Scribe" "scribe" ~conv:Conv.string
      : string Ty.t )

  let belongs = Builtins.belongs ty
end

module Find_spot = struct
  type t = string [@@deriving yojson]

  let ty =
    ( Ty.Custom.add ~friendly:"Find spot (inside the site)" "find-spot"
        ~conv:Conv.string
      : string Ty.t )

  let belongs = Builtins.belongs ty
end

module Kind = struct
  type t = string [@@deriving yojson]

  let ty =
    ( Ty.Custom.add ~friendly:"Document type" "kind" ~conv:Conv.string
      : string Ty.t )

  let belongs = Builtins.belongs ty
end

module Metadata = struct
  type impression =
    { number: int
    ; kind: string option [@default None]
    ; motif: string option [@default None]
    ; url: string option [@default None] }
  [@@deriving yojson]

  type t =
    { kind: Kind.t
    ; name: string
    ; location: Location.t
    ; findspot: string option [@default None]
    ; series: string option [@default None]
    ; scribe: string option [@default None]
    ; size: Size.t option [@default None]
    ; date: Period.t option [@default None]
    ; url: string option [@default None]
    ; impressions: impression option [@default None]
    ; global_comment: (string * string) option [@default None]
    ; occ_comments: (int * (string * string) option) list [@default []] }
  [@@deriving yojson]

  let of_file = json_of_file of_yojson
end

type ('doc, 'attestation) _sequence =
  { seq_doc: 'doc
  ; items: 'attestation list
  ; bounds: Bound.t * Bound.t
  ; index: int }
[@@deriving yojson]

type 'doc _attestation =
  { att_doc: 'doc
  ; reading: Script.Reading.t
  ; number: int
  ; role: Role.t [@default Role.Unknown, Uncertainty.Unsure]
  ; erasure: bool
  ; att_comment: (string * string) option [@default None]
  ; ghost: bool [@default false]
  ; position: Rect.t }
[@@deriving yojson]

type ('doc, 'attestation) _document =
  { metadata: Metadata.t
  ; path: string
  ; sequences: ('doc, 'attestation) _sequence array
  ; image_size: int * int
  ; signs: 'doc _attestation array }
[@@deriving yojson]

type sequence = (document Lazy.t, attestation) _sequence

and attestation = document Lazy.t _attestation

and document = D of (document Lazy.t, attestation) _document

let unD (D x) = x

let meta (D {metadata; _}) = metadata

let name (D {metadata = {name; _}; _}) = name

let url (D {metadata = {url; _}; _}) = url

let kind (D {metadata = {kind; _}; _}) = kind

let signs (D {signs; _}) = signs

let sequences (D {sequences; _}) = sequences

let image_size (D {image_size; _}) = image_size

let period doc = (meta doc).date

let scribe doc = (meta doc).scribe

let find_spot doc =
  Option.map (fun s -> (meta doc).location ^ "/" ^ s) (meta doc).findspot

let comment doc = (meta doc).global_comment

let location (D {metadata = {location; _}; _}) = location

let size (D {metadata = {size; _}; _}) = size

let path_ name = "document" // name

let path doc = path_ (name doc)

let image_ name = path_ name // (name ^ ".png")

let image doc = image_ (name doc)

type t = document

let to_yojson (D document) =
  _document_to_yojson
    (fun _ -> [%to_yojson: unit] ())
    (fun x -> `Int x.number)
    document

let of_yojson json =
  let rec document =
    lazy
      (let doc =
         Result.get_ok
         @@ _document_of_yojson
              (fun _ -> return document)
              [%of_yojson: int] json
       in
       let sequences =
         let lookup i n =
           try doc.signs.(n - 1)
           with _ ->
             warning
               "**** IMPORTANT ***** In sequence %d, reference to unknown index %d (Number of signs: %d)"
               i n (Array.length doc.signs) ;
             doc.signs.(0)
         in
         Array.mapi
           (fun i seq -> {seq with items = List.map (lookup i) seq.items})
           doc.sequences
       in
       D {doc with sequences} )
  in
  return @@ Lazy.force document

let ty : t Ty.t = Ty.Custom.add ~friendly:"Linear A Document" "document"

let index occurrences n =
  if n - 1 < Array.length occurrences then n - 1
  else
    try findi (fun o -> o.number = n) (Array.to_list occurrences)
    with Not_found ->
      warning "Reference to unknown occurrence %d. (Nb of occ: %d)" n
        (Array.length occurrences) ;
      raise Not_found

let at (D doc) n =
  try doc.signs.(index doc.signs n)
  with Not_found ->
    warning "Invalid reference to occurrence %d of %s" n doc.metadata.name ;
    raise Not_found

let sequence (D doc) n =
  try doc.sequences.(n - 1)
  with _ ->
    failwith
    @@ Printf.sprintf "Invalid reference to sequence %d of %s" n
         doc.metadata.name

let blocation =
  Builtins.register_ ~param:Ty.unit ~inp:ty ~ret:Location.ty
    ~friendly:"Found site" "doc-site" ~eval:(fun _ -> location)

let bkind =
  Builtins.register_ ~param:Ty.unit ~inp:ty ~ret:Kind.ty
    ~friendly:"Inscription type" "doc-type" ~eval:(fun _ -> kind)

let bperiod =
  Builtins.register_ ~param:Ty.unit ~inp:ty ~ret:Period.ty ~friendly:"Period"
    "period" ~eval:(fun _ d -> Option.value ~default:"" (period d))

let bscribe =
  Builtins.register_ ~param:Ty.unit ~inp:ty ~ret:Scribe.ty ~friendly:"Scribe"
    "scribe" ~eval:(fun _ d -> Option.value ~default:"" (scribe d))

let bfindspot =
  Builtins.register_ ~param:Ty.unit ~inp:ty ~ret:Period.ty
    ~friendly:"Find spot (inside the site)" "find-spot" ~eval:(fun _ d ->
      Option.value ~default:"" (find_spot d) )

let motif =
  Builtins.register_ ~param:Ty.unit ~inp:ty ~ret:Motif.ty
    ~friendly:"Impression seal motifs" "motif" ~eval:(fun _ d ->
      match (meta d).impressions with
      | Some {motif = Some motif; _} -> motif
      | _ -> "" )

module Attestation = struct
  type t = attestation

  let ty : t Ty.t = Ty.Custom.add ~friendly:"Sign attestation" "attestation"

  let document {att_doc = (lazy d); _} = d

  let is_left att =
    let (D doc) = document att in
    att.position.x + (att.position.w / 2) < fst doc.image_size / 2

  let location x = location (document x)

  let scribe x = Option.value (scribe (document x)) ~default:"Unknown"

  let find_spot x = Option.value (find_spot (document x)) ~default:"Unknown"

  let kind x = kind (document x)

  let sign x = Script.Reading.sign x.reading

  let document_name x = name (document x)

  let blocation =
    Builtins.register_ ~param:Ty.unit ~inp:ty ~ret:Location.ty
      ~friendly:"Found site" "att-site" ~eval:(fun _ -> location)

  let bscribe =
    Builtins.register_ ~param:Ty.unit ~inp:ty ~ret:Location.ty
      ~friendly:"Scribe" "att-scribe" ~eval:(fun _ -> scribe)

  let bfind_spot =
    Builtins.register_ ~param:Ty.unit ~inp:ty ~ret:Find_spot.ty
      ~friendly:"Deposit (inside the site) " "att-find-spot" ~eval:(fun _ ->
        find_spot )

  let bkind =
    Builtins.register_ ~param:Ty.unit ~inp:ty ~ret:Kind.ty
      ~friendly:"Inscription type" "att-type" ~eval:(fun _ -> kind)

  let bmatches =
    Builtins.register_ ~param:Script.CompositionPattern.ty ~inp:ty
      ~ret:Ty.bool ~friendly:"An attestation of" "sign-match"
      ~eval:(fun pat x -> Script.CompositionPattern.matches pat x.reading)

  let _ =
    Builtins.register ~param:Ty.unit ~inp:ty ~ret:Ty.string
      ~friendly:"Document name" "occ-doc-name" ~eval:(fun _ x ->
        name (document x) )

  let _ =
    Builtins.register ~param:Ty.unit ~inp:ty ~ret:Script.Reading.ty
      ~friendly:"Reading" "occ-reading" ~eval:(fun _ x -> x.reading)

  let image_ name number =
    path_ name // Printf.sprintf "%s_%d.png" name number

  let image occ = image_ (document_name occ) occ.number

  let label_ name number =
    path_ name // Printf.sprintf "%s_%d.label" name number

  let label occ = label_ (document_name occ) occ.number
end

module Sequence = struct
  type t = sequence

  let ty : t Ty.t = Ty.Custom.add ~friendly:"Sign sequence" "sequence"

  let document {seq_doc = (lazy d); _} = d

  let document_name x = name (document x)

  let kind x = kind (document x)

  let location x = location (document x)

  let first w = List.hd w.items

  let last w = List.(hd (rev w.items))

  let is_left word =
    let (D doc) = document word in
    let first = first word and last = last word in
    (first.position.x + last.position.x + last.position.w) / 2
    < fst doc.image_size / 2

  let blocation =
    Builtins.register_ ~param:Ty.unit ~inp:ty ~ret:Location.ty "seq-site"
      ~friendly:"Found site" ~eval:(fun _ -> location)

  let bkind =
    Builtins.register_ ~param:Ty.unit ~inp:ty ~ret:Kind.ty "seq-kind"
      ~friendly:"Inscription type" ~eval:(fun _ -> location)

  let _ =
    Builtins.register ~param:Ty.unit ~inp:ty ~ret:Ty.string
      ~friendly:"Document name" "seq-doc-name" ~eval:(fun _ x ->
        name (document x) )
end

let compare d1 d2 = compare (name d1) (name d2)

let meta_file doc = path doc // "meta.json"

module SequencePattern = struct
  type t = Script.Reading.t Regexp.t [@@deriving yojson]

  let parse = Regexp.parse Script.Reading.parse

  let show_plain =
    Regexp.show (Script.Reading.show ~f:Script.Sign.show_syllabogram)

  let conv = Conv.make ~parse ~show:(Regexp.show Script.Reading.show)

  let ty = Ty.Custom.add ~friendly:"Sequence pattern" "seq-pattern" ~conv

  let of_word (w : Sequence.t) =
    List.map (fun x -> Regexp.Char x.reading) w.items

  let matches regexp (word : Sequence.t) =
    try
      Regexp.subset Script.Reading.matches (of_word word) regexp
      && ( fst word.bounds <> Not_here
         || (regexp <> [] && List.hd regexp = Star) )
      && ( snd word.bounds <> Not_here
         || (regexp <> [] && List.nth regexp (List.length regexp - 1) = Star)
         )
    with e ->
      Printf.eprintf "While matching word %s:%d: %s"
        (Sequence.document_name word)
        word.index (Printexc.to_string e) ;
      false

  let bmatches =
    Builtins.register_ ~param:ty ~inp:Sequence.ty ~ret:Ty.bool
      ~friendly:"Matches the pattern:" "word-match" ~eval:(fun pat x ->
        matches pat x )
end

let _ =
  let f = Builtins.register ~param:Ty.unit ~inp:ty in
  f
    ~ret:Ty.(list Attestation.ty)
    ~eval:(fun _ x -> Array.to_list (signs x))
    ~friendly:"Signs attested in the inscription" "doc-signs" ;
  f ~ret:Ty.string ~eval:(fun _ -> name) ~friendly:"Document name" "doc-name" ;
  f
    ~ret:Ty.(list Sequence.ty)
    ~eval:(fun _ x -> Array.to_list (sequences x))
    ~friendly:"Sequences attested in the inscription" "doc-seq" ;
  f ~ret:Ty.float
    ~eval:(fun _ x -> Option.(value ~default:1.0 (map Size.width (size x))))
    ~friendly:"Width" "width" ;
  f ~ret:Ty.float
    ~eval:(fun _ x -> Option.(value ~default:1.0 (map Size.height (size x))))
    ~friendly:"Height" "height" ;
  f ~ret:Ty.float
    ~eval:(fun _ x -> Option.(value ~default:1.0 (map Size.depth (size x))))
    ~friendly:"Depth" "depth" ;
  let f = Builtins.register ~param:Ty.unit ~inp:Attestation.ty in
  f ~ret:ty
    ~eval:(fun _ x -> Attestation.document x)
    ~friendly:"Inscription attestation was found on" "sign-doc" ;
  f ~ret:Role.ty
    ~eval:(fun _ x -> x.role)
    ~friendly:"Function of the attestation" "function" ;
  f ~ret:Ty.bool
    ~eval:(fun _ x -> x.erasure)
    ~friendly:"Is an erasure" "erasure?" ;
  ()
