open LinearA
module Document = LinearA.Document

module Make (M : Basic.Model) = struct
  open M
  module B = Basic.Make (M)
  open B
  open Html

  module Bounds = struct
    let show (a, b) x =
      txt (Bound.show_before a) :: x @ [txt (Bound.show_after b)]
  end

  module Reading = struct
    let show show_inst = function
      | Script.Unreadable -> [txt "[?]"]
      | Unclassified ->
          [span ~a:[a_class ["unclassified"]] [txt "[unclassified]"]]
      | Readable {instance; confidence} ->
          let data = show_inst instance in
          if confidence then [span ~a:[a_class ["sure-reading"]] data]
          else [span ~a:[a_class ["unsure-reading"]] data]
  end

  module Composition = struct
    open Composition

    let show show_data (x, bounds) =
      let rec aux = function
        | Simple x -> show_data x
        | Composed ls ->
            concat' (fun () -> [txt " + "]) @@ List.map (show true) ls
        | TightJuxtaposition ls ->
            concat' (fun () -> [txt " | "]) @@ List.map (show true) ls
        | LooseJuxtaposition ls ->
            concat' (fun () -> [txt " ∥ "]) @@ List.map (show true) ls
        | Fuse (a, b) ->
            show false a @ [txt " + {"] @ show false b @ [txt "}"]
      and show b x =
        match (b, x) with
        | _, Simple x -> show_data x
        | false, x -> aux x
        | true, x -> [txt "("] @ aux x @ [txt ")"]
      in
      Bounds.show bounds (aux x)
  end

  module PhoneticValue = struct
    open PhoneticValue

    let show {value; annot} =
      [fmt "%s" value]
      @ match annot with Some x -> [sub [txt x]] | None -> []
  end

  module Sign = struct
    let image ~cl sign =
      let make_img src =
        [ img
            ~a:
              [ a_class [cl]
              ; a_title (Printf.sprintf "Sign %s" (Script.Sign.show sign)) ]
            ~src
            ~alt:(Printf.sprintf "Sign %s" (Script.Sign.show sign))
            () ]
      in
      match Database.representative_image_sign sign with
      | Some src -> make_img (Url.ressource src)
      | None -> (
        try make_img @@ Url.ressource @@ snd @@ List.hd sign.variants
        with _ -> [] )

    let show_formal t =
      let x = t.Script.prefix ^ Printf.sprintf "%02d" t.Script.number in
      if t.vessel then [txt x; sup [txt "VAS"]] else [txt x]

    let show_syllabogram t =
      match t.Script.phonetic with
      | Some x -> PhoneticValue.show x
      | _ when t.prefix = "AB" -> [fmt "*%02d" t.number]
      | _ -> show_formal t

    let show_logogram t =
      match t.Script.latin with
      | Some x -> show_formal t @ [txt "/"; txt x]
      | _ -> show_formal t

    let miniature sign =
      match image ~cl:"sign-image-nice" sign with
      | [] -> show_formal sign
      | l -> [inline_figure ~caption:(show_formal sign) l]

    let show_image_or_formal ~cl sign =
      match image ~cl sign with [] -> show_formal sign | l -> l
  end

  module Instance = struct
    let show show_meta {Script.sign; variant} =
      let m = show_meta sign in
      match variant with Some x -> m @ [sup [txt x]] | _ -> m

    let image ?(cl = "") inst =
      let make_img src =
        [ img
            ~a:
              [ a_class [cl]
              ; a_title
                  (Printf.sprintf "Sign %s" Script.(Sign.show inst.sign)) ]
            ~src
            ~alt:(Printf.sprintf "Sign %s" Script.(Sign.show inst.sign))
            () ]
      in
      match Database.representative_image_inst inst with
      | None -> Sign.image ~cl inst.sign
      | Some s -> make_img (Url.ressource s)

    let miniature sign =
      match image ~cl:"sign-image-nice" sign with
      | [] -> show Sign.show_formal sign
      | l -> [inline_figure ~caption:(show Sign.show_formal sign) l]

    let show_full instance =
      let si = instance.Script.sign in
      concat' (fun () -> [txt "/"])
      @@ List.filter_map
           (fun x -> x)
           [ Some (show Sign.show_formal instance)
           ; Option.map PhoneticValue.show si.phonetic
           ; Option.map (fun x -> [txt x]) si.latin ]
  end

  let decomposition ?(cl = "composition") ?(prefix = "") ?(suffix = "") si =
    match Script.composition si with
    | None -> []
    | Some c ->
        [ span
            ~a:[a_class [cl]]
            ( fmt "%s" prefix
              :: Composition.show (Reading.show Instance.miniature) c
            @ [fmt "%s" suffix] ) ]

  module Attestation = struct
    let show_bare occ =
      Reading.show
        ( match occ.Document.role with
        | Role.Logogram, _ -> Instance.show Sign.show_logogram
        | Role.Syllabogram (_, _), _ -> Instance.show Sign.show_syllabogram
        | _ -> Instance.show Sign.show_formal )
        occ.reading

    let sign_menu document instance =
      [span ~a:[a_class ["menu-title"]] @@ Instance.show_full instance]
      @ decomposition ~prefix:"  (" instance.sign ~suffix:")"
      @ [br ()]
      @ B.link
          (Url.search_results
             (Search.sign ~sign:(Search.pattern_of_instance instance) ()) )
          "View all occurrences of this sign"
      @ [br ()]
      @ B.link
          (Url.search_results
             (Search.sign
                ~location:[Document.location document]
                ~sign:(Search.pattern_of_instance instance)
                () ) )
          ( "View all occurrences of this sign at "
          ^ Document.location document )

    let show ?menu:(_ = false) att =
      let bare = show_bare att in
      match att.reading with
      | Readable {instance; _} ->
          [ a
              ~a:
                [ a_title "View attestations of this sign"
                ; a_href (Url.instance instance) ]
              bare ]
      | _ -> bare

    let image occurrence =
      let path = Url.occ_image occurrence in
      span
        ~a:[a_class ["center"]]
        [img ~a:[a_height 100] ~src:path ~alt:"Image of the occurrence" ()]

    let class_of occurrence =
      if occurrence.Document.erasure then ["erasure"]
      else
        match occurrence.Document.role with
        | Role.Syllabogram (_, k), _ ->
            ["syllabogram"; (if k mod 2 = 0 then "even" else "odd")]
        | TransactionSign, _ -> ["transaction"]
        | x, _ -> [Role.show_kind x]

    let function_of occurrence =
      ( if occurrence.Document.erasure then Printf.sprintf "Erasure (%s)"
      else fun s -> s )
        (String.capitalize_ascii @@ Role.show occurrence.role)

    let miniature x =
      let doc = LinearA.Document.Attestation.document x in
      B.figure
        ~caption:
          ( B.link (Url.document doc) (LinearA.Document.name doc)
          @ [txt ": "] @ show ~menu:true x )
        (link_ (Url.occurrence x) [image x])
  end

  module Sequence = struct
    let show_bare (word : Document.Sequence.t) =
      let signs =
        List.map
          (fun {Document.number; _} ->
            Document.at (Document.Sequence.document word) number )
          word.Document.items
      in
      [txt (Bound.show_before (fst word.bounds))]
      @ concat
          (fun () -> txt "-")
          (List.concat @@ List.map Attestation.show_bare signs)
      @ [txt (Bound.show_after (snd word.bounds))]

    let show ?menu:(_ = false) (wo : Document.Sequence.t) =
      [ a
          ~a:
            [ a_title "View othe rattestations of this sequence"
            ; a_href (Url.word wo) ]
          (show_bare wo) ]
  end
end
