open Prelude
open Parsing

type 'a t = {show: 'a -> string; parse: 'a Parsing.t}

let scanf fmt f s = catch (Scanf.sscanf s fmt) f

let string =
  let parse =
    let* _ = char '"' <?> "Quote string: expected '\"'" in
    let* _, cs =
      scan_state (false, []) (fun (is_escaped, cs) c ->
          match c with
          | '"' when not is_escaped -> None
          | '\\' when not is_escaped -> Some (true, cs)
          | _ -> Some (false, c :: cs) )
    in
    let cs = Array.of_list cs in
    let* _ = char '"' in
    return
      (String.init (Array.length cs) (fun i -> cs.(Array.length cs - i - 1)))
  in
  {show = Printf.sprintf "%S"; parse}

let int = {show = Printf.sprintf "%d"; parse = Parsing.int}

let raw = {show = (fun s -> s); parse = take_while (fun _ -> true)}

let map forward backward c =
  {show = (fun x -> c.show (backward x)); parse = c.parse >>| forward}

let make ~show ~parse = {show; parse}

let parse conv s = Parsing.run conv.parse s

let show conv x = conv.show x

let list conv =
  { show =
      (fun l ->
        Printf.sprintf "[%s]" (String.concat ";" (List.map conv.show l)) )
  ; parse = Parsing.delim "[" "]" Parsing.(sep_by (keyword ";") conv.parse)
  }

let pair a b =
  { show = (fun (x, y) -> Printf.sprintf "(%s, %s)" (a.show x) (b.show y))
  ; parse = Parsing.pair a.parse b.parse }

let unit = {show = (fun () -> ""); parse = Parsing.(string "" >> return ())}

let float = {parse = Parsing.float; show = Printf.sprintf "%f"}

let bool =
  { show = Bool.to_string
  ; parse =
      Parsing.(
        string "true" >> return true <|> (string "false" >> return false)) }

let test c x =
  let open Prelude in
  let* x' = parse c (show c x) in
  return x'

let option c =
  let show = function None -> "" | Some x -> c.show x in
  {show; parse = Parsing.option c.parse}
