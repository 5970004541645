(* Entry point of the web interface *)
module F = Form
open Js_of_ocaml
open Model
open M.Html

let result_div = div []

let edit_button = span []

let show_form b =
  match Dom_html.getElementById_opt "search-form" with
  | None -> ()
  | Some x ->
      x##.style##.display
      := if b then Js.string "block" else Js.string "none"

let edit () =
  set_content edit_button [] ;
  show_form true

let setup_results ?(modify_hash = true) search =
  if modify_hash then
    Dom_html.window##.location##.hash
    := Js.encodeURI @@ Js.string LinearA.Search.(show (Search search)) ;
  let content =
    Grouping.show_search (Socket.db ())
      (fun g ->
        [ Basic.fmt "There are %d results to the query. "
            (Core.Grouping.length g)
        ; edit_button ] )
      search
  in
  show_form false ;
  set_content edit_button
    [ action ~cl:["edit"; "button"] ~title:"Edit the current query" edit
        [txt "Edit query"] ] ;
  set_content result_div content

let search_form (LinearA.Search.Search search) =
  [ div ~a:[a_id "search-form"]
      (F.ask_search search.typ (Some search) setup_results) ]

let get_hash () =
  let s = Js.to_string @@ Js.decodeURI Dom_html.window##.location##.hash in
  try String.sub s 1 (String.length s - 1) with _ -> ""

let default_search typ =
  let open Core.Ty in
  let open LinearA.Document in
  let module M = Matcher (struct
    type 'a t = LinearA.Search._t
  end) in
  M.run_clauses typ
    [ M.on ty ~return:(LinearA.Search.document ())
    ; M.on Attestation.ty ~return:(LinearA.Search.sign ())
    ; M.on Sequence.ty ~return:(LinearA.Search.word ()) ]

let setup_search (elem : Dom_html.element Js.t) =
  let s =
    Js.to_string
    @@ Js.Opt.get
         (elem##getAttribute (Js.string "data-typ"))
         (fun _ -> Js.string "")
  in
  let default_search =
    match Core.Parsing.run Core.Ty.Untyped.conv.parse s with
    | Ok (Core.Ty.Untyped.T typ) -> default_search typ
    | _ -> default_search LinearA.Document.ty
  in
  let hash = get_hash () in
  let search, result =
    match Core.Parsing.run LinearA.Search.parse hash with
    | Error _ -> (default_search, false)
    | Ok x -> (Ok x, true)
  in
  match search with
  | Error e -> print_endline e
  | Ok search ->
      let html = search_form search in
      let content = html @ [result_div] in
      (try Model.set_content_node elem (List.map cast content) with _ -> ()) ;
      if result then
        let (LinearA.Search.Search s) = search in
        setup_results ~modify_hash:false s

let main _ =
  Socket.load () ;
  let root = try Js.to_string @@ Js.Unsafe.variable "root" with _ -> "" in
  Generation.Url.root := root ;
  QuickSearch.setup "quick-search" ;
  (try setup_search (Dom_html.getElementById "search-app") with _ -> ()) ;
  Dom_html.(
    window##.onhashchange :=
      Dom_html.handler (fun _ ->
          let hash = get_hash () in
          match Core.Parsing.run LinearA.Search.parse hash with
          | Error e ->
              Printf.eprintf "Invalid search: %s\nSearch string is: %s\n%!" e
                hash ;
              Js._false
          | Ok (Search x) ->
              setup_results ~modify_hash:false x ;
              Js._false )) ;
  Js._false

let () = Dom_html.(window##.onload := Dom_html.handler main)
