open Core

type t = {value: string; annot: string option [@default None]}
[@@deriving yojson]

type 'a Ty.custom += PhoneticValue : t Ty.custom

let show {value; annot} =
  Printf.sprintf "%s%s" value
    (match annot with Some x -> "(" ^ x ^ ")" | _ -> "")

let cast (type a) : a Ty.custom -> (t, a) Equality.t option = function
  | PhoneticValue -> Some Equality.Refl
  | _ -> None

let typ : t Ty.t = Ty.Custom.add ~friendly:"Phonetic value" "phonetic-value"
