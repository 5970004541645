open Tyxml
module X = Xml

module type Model = sig
  module Xml :
    Xml_sigs.T with module W = Xml_wrap.NoWrap with type uri = string

  module Svg : Svg_sigs.T with module Xml := Xml

  module Html : Html_sigs.Make(Xml)(Svg).T

  module Dynamic : sig
    val show : string -> Xml.mouse_event_handler

    val hide : string -> Xml.mouse_event_handler
  end
end

module Make (M : Model) = struct
  let amap f a = List.map f @@ Array.to_list a

  open M
  open M.Html
  open LinearA.Document

  let map_signs doc f =
    List.filter_map (fun o -> if o.ghost then None else Some (f o))
    @@ Array.to_list (signs doc)

  let map_sequences doc f = amap f (sequences doc)

  let fmt fmt = Printf.kprintf txt fmt

  let empty = Html.Unsafe.data ""

  let guard b e = if b then e else empty

  let concat sep l =
    let rec aux = function
      | [] -> []
      | [t] -> [t]
      | t :: q -> t :: sep () :: aux q
    in
    aux (List.filter (fun x -> x <> empty) l)

  let concat' sep l =
    let rec aux = function
      | [] -> []
      | [t] -> [t]
      | t :: q -> t :: sep () :: aux q
    in
    List.concat @@ aux (List.filter (fun x -> x <> []) l)

  let unless b e = if not b then e else empty

  let inline_figure ~caption figure =
    span
      ~a:[a_class ["miniature"]]
      (figure @ [span ~a:[a_class ["caption"]] caption])

  let link ?title:(tit = "") href text =
    Html.[a ~a:[a_title tit; a_href href] [txt text]]

  let link_ ?title:(tit = "") href text =
    Html.[a ~a:[a_title tit; a_href href] text]

  let link_f f x = link (f x) x

  let opt f = function Some x -> f x | _ -> [%html ""]

  let rect cl ?(attrs = []) ?on_out ?on_hover ?on_click {Core.Rect.x; y; w; h}
      =
    let opt attr = function None -> [] | Some f -> [attr f] in
    let open Svg in
    rect
      ~a:
        ( attrs @ opt a_onclick on_click
        @ opt a_onmouseover on_hover
        @ opt a_onmouseout on_out
        @ [ a_class cl
          ; a_rx (10., Some `Px)
          ; a_ry (10., Some `Px)
          ; a_x (float x, None)
          ; a_y (float y, None)
          ; a_width (float w, None)
          ; a_height (float h, None) ] )
      []

  let image img {Core.Rect.x; y; w; h} =
    Svg.(
      image
        ~a:
          [ a_x (float x, None)
          ; a_y (float y, None)
          ; a_width (float w, None)
          ; a_height (float h, None)
          ; a_href img ]
        [])

  let viewBox {Core.Rect.x; y; w; h} =
    Svg.a_viewBox (float x, float y, float w, float h)

  let menu text items =
    let contents = span ~a:[a_class ["menu-contents"]] items in
    span ~a:[a_class ["menu"]] (text @ [contents])

  type 'a page = {content: 'a; home: bool; title: string; javascript: string}

  let page ?(home = false) ?(javascript = "") title content =
    {home; javascript; title; content}

  let figure ~caption figure =
    div
      ~a:[a_class ["miniature"]]
      (figure @ [div ~a:[a_class ["caption"]] caption])

  (* Template *)

  let topbar () =
    [ a
        ~a:[a_href @@ Url.ressource "index.html"]
        [ img
            ~a:[a_class ["logo"]]
            ~src:(Url.ressource "logo.png")
            ~alt:"SigLA logo" ()
        ; txt "Home" ] ]
    @ link (Url.ressource "about.html") "About"
    @ link (Url.ressource "help.html") "Help"

  let sidebar () =
    aside ~a:[a_id "sidebar"]
      [ div
          ~a:[a_id "side-quick-search"]
          [ img
              ~a:[a_class ["sidebar-icon"]]
              ~src:(Url.ressource "search.png")
              ~alt:"Quick search" ()
          ; span ~a:[a_id "quick-search"] [] ]
      ; div ~a:[a_id "search"]
          [ img
              ~a:[a_class ["sidebar-icon"]]
              ~src:(Url.ressource "binocular.png")
              ~alt:"Complex searches" ()
          ; span
              ~a:[a_class ["search-content"]]
              ( link (Url.search_document ()) "Documents"
              @ [txt " ∗ "]
              @ link (Url.search_sign ()) "Signs"
              @ [txt " ∗ "]
              @ link (Url.search_sequence ()) "Sequences" ) ] ]

  let footer () =
    footer
      ( [txt "© 2020- "]
      @ link
          (Url.ressource "about.html")
          "Ester Salgarella and Simon Castellan"
      @ [txt ". Dataset and drawings are available under the "]
      @ link "https://creativecommons.org/licenses/by-nc-sa/4.0/"
          "CC
        BY-NC-SA 4.0"
      @ [txt " license."] )

  let render_body {title; content; home; _} =
    [ header (topbar ())
    ; guard (not home) (sidebar ())
    ; main ~a:[a_id "main"]
        (div ~a:[a_class ["title"]] [txt title] :: content)
    ; footer () ]

  let render ({title; javascript; home; _} as page) =
    let body_attr = if home then [a_class ["home"]] else [] in
    html
      (head
         (Html.title (txt title))
         [ Html.meta ~a:[a_charset "utf-8"] ()
         ; script
             ~a:
               [ a_charset "utf-8"
               ; a_mime_type "text/javascript"
               ; a_src (Url.database_javascript ()) ]
             (txt "")
         ; script
             ~a:
               [ a_charset "utf-8"
               ; a_mime_type "text/javascript"
               ; a_src (Url.javascript ()) ]
             (txt "")
         ; script
             ~a:[a_charset "utf-8"; a_mime_type "text/javascript"]
             ( Unsafe.data
             @@ Printf.sprintf "var root = '%s';\n%s" !Url.root javascript )
         ; Html.link ~rel:[`Stylesheet] ~href:(Url.ressource "style.css") ()
         ] )
      (body ~a:body_attr (render_body page))
end
