let compare_char c c' =
  match (c, c') with
  | '0' .. '9', '0' .. '9' -> compare c c'
  | '0' .. '9', _ -> -1
  | _, '0' .. '9' -> 1
  | _, _ -> compare c c'

let is_digit c = match c with '0' .. '9' -> true | _ -> false

let ios_at s n =
  let rec aux k i =
    if i = String.length s then (k, i)
    else if not @@ is_digit s.[i] then (k, i)
    else aux ((10 * k) + Char.(code s.[i] - code '0')) (i + 1)
  in
  aux 0 n

let compare_string s s' =
  let rec aux i =
    if i >= String.length s' then 1
    else if i >= String.length s then -1
    else
      match (s.[i], s'.[i]) with
      | '0' .. '9', '0' .. '9' ->
          let n1, o = ios_at s i and n2, _ = ios_at s' i in
          let n = compare n1 n2 in
          if n = 0 then aux o else n
      | _, _ ->
          let n = compare s.[i] s'.[i] in
          if n = 0 then aux (i + 1) else n
  in
  aux 0

let array = Array.sort compare_string

let list = List.sort compare_string

module String = struct
  type t = string

  let compare = compare_string
end
