module Make (Model : Basic.Model) = struct
  module Grouping = Grouping.Make (Model)
  module Basic = Basic.Make (Model)
  module Sign = Sign.Make (Model)
  open Basic
  open Model.Html
  open Model

  let from_search x title s db () =
    let (LinearA.Search.Search s) = s in
    page title
    @@ Grouping.show_search db
         (fun g -> [fmt x (Core.Grouping.length g); br ()])
         s

  let browse =
    from_search "There are %d documents in the corpus." "Browse corpus"
      (LinearA.Search.document ())

  let location (loc : string) =
    from_search
      ( "There are %d documents found at "
      ^^ Scanf.format_from_string loc ""
      ^^ "." )
      (Printf.sprintf "Location: %s" loc)
      (LinearA.Search.document ~location:[loc] ())

  let kind (k : string) =
    let k' = String.uncapitalize_ascii k in
    from_search
      ( "There are %d documents found on "
      ^^ Scanf.format_from_string k' ""
      ^^ "s." )
      (Printf.sprintf "Document type: %s" k)
      (LinearA.Search.document ~types:[k] ())

  let period (p : string) =
    from_search
      ( "There are %d documents found during period "
      ^^ Scanf.format_from_string p ""
      ^^ "." )
      (Printf.sprintf "Period: %s" p)
      (LinearA.Search.document ~period:[p] ())

  let map () =
    let make_map (image_size, png, rects) =
      let box =
        {Core.Rect.x = 0; y = 0; w = fst image_size; h = snd image_size}
      in
      let make_link (rect, link) =
        Svg.a ~a:[Svg.a_href link] [Basic.rect ["map-link"] rect]
      in
      div
        ~a:[a_class ["map"]]
        [ svg
            ~a:
              Svg.
                [ viewBox box
                ; a_width (100., Some `Percent)
                ; a_height (100., Some `Percent) ]
            (image png box :: List.map make_link rects) ]
    in
    let open Core in
    let crete =
      ( (1731, 755)
      , "maps/crete.png"
      , [ (Rect.{x = 255; y = 192; w = 77; h = 55}, Url.location "Khania")
        ; ( Rect.{x = 867 - 80; y = 532 - 55; w = 80; h = 55}
          , Url.location "Phaistos" )
        ; (Rect.{x = 967; y = 386; w = 100; h = 55}, Url.location "Arkhanes")
        ; (Rect.{x = 948; y = 304; w = 100; h = 86}, Url.location "Knossos")
        ; (Rect.{x = 1159; y = 322; w = 67; h = 62}, Url.location "Mallia")
        ; (Rect.{x = 844; y = 337; w = 119; h = 39}, Url.location "Tylissos")
        ; (Rect.{x = 1643; y = 458; w = 80; h = 57}, Url.location "Zakros")
        ; ( Rect.{x = 800 - 150; y = 507; w = 150; h = 100}
          , Url.location "Haghia Triada" ) ] )
    in
    page "Maps of Linear A sites" [make_map crete]

  let search_result ty =
    let s = Core.Ty.show ty in
    ( "search-" ^ s ^ ".html"
    , fun () ->
        page
          (String.capitalize_ascii s ^ " search")
          [div ~a:[a_id "search-app"; Unsafe.string_attrib "data-typ" s] []]
    )

  let sign_list () =
    let open LinearA in
    let show_instance instance =
      let si = instance.Script.sign in
      let signnumber = Sign.Instance.show_full instance in
      if instance.variant = None && si.rep = None then empty
      else
        div
          ~a:[a_class ["flex-table-item"]]
          ( [span ~a:[a_class ["signnumber"]] signnumber]
          @ link_ (Url.instance instance)
              ( match Sign.Instance.image ~cl:"sign-image-big" instance with
              | [] ->
                  [ span
                      ~a:[a_class ["sign-image-big"]]
                      (Sign.Sign.show_formal si) ]
              | l -> l )
          @
          match Sign.decomposition ~prefix:"= " si with
          | [] -> []
          | l -> [span l] )
    in
    let instances = Script.Instance.list () in
    let simple, composite =
      List.partition
        (fun {Script.sign; _} -> sign.Script.composition = None)
        instances
    in
    let normal, fraction =
      List.partition
        (fun {Script.sign; _} ->
          match Script.Sign.default_role sign with
          | Role.Fraction, _ -> false
          | _ -> true )
        simple
    in
    let table_of c l =
      div ~a:[a_class ["flex-table"; c]] (List.map show_instance l)
    in
    page "Sign list"
      [ h2 [txt "Simple signs"]
      ; table_of "small" normal
      ; h2 [txt "Composite signs"]
      ; table_of "big" composite
      ; h2 [txt "Fraction signs"]
      ; table_of "small" fraction ]

  let read_file fname =
    let fd = open_in fname in
    let rec loop s =
      match input_line fd with s' -> loop (s ^ s') | exception _ -> s
    in
    let v = loop "" in
    close_in fd ; v

  let static ?(title = "") fname =
    let output_file = Filename.chop_suffix fname ".static.html" ^ ".html" in
    print_endline output_file ;
    let contents = read_file fname in
    ( Filename.basename output_file
    , fun () -> page title [Unsafe.data contents] )
end
