(* Home Page *)

module Make (M : Basic.Model) = struct
  open M
  module Basic = Basic.Make (M)
  open Html
  open Basic

  let html () =
    [ div
        ~a:[a_class ["subtitle"]]
        [txt "The signs of Linear A: a paleographical database"]
    ; div ~a:[a_id "home"]
        [ span ~a:[a_id "quick-search"] []
        ; span ~a:[a_class ["home-search"]] [txt "Search"]
        ; span ~a:[a_class ["sep"]] []
        ; span ~a:[a_class ["home-browse"]] [txt "Explore"]
        ; span
            ~a:[a_class ["home-search-sign"]]
            (link (Url.search_sign ()) "Sign")
        ; span
            ~a:[a_class ["home-search-sequence"]]
            (link (Url.search_sequence ()) "Sequence")
        ; span
            ~a:[a_class ["home-search-document"]]
            (link (Url.search_document ()) "Document")
        ; span ~a:[a_class ["home-map"]] (link (Url.map ()) "Map")
        ; span
            ~a:[a_class ["home-sign-list"]]
            (link (Url.sign_list ()) "Sign list")
        ; span
            ~a:[a_class ["home-document-list"]]
            (link (Url.browse ()) "Browse corpus") ] ]

  let page = ("index.html", fun () -> page ~home:true "SigLA" (html ()))
end
