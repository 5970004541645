open LinearA
module FC = FormCombinator
open Model
open M.Html
open Core

let builtins typ =
  let open Builtins in
  let properties, pred = List.partition is_property (of_typ typ) in
  let f (B b) = (b.friendly, B b) in
  ( `Placeholder "Choose an operation"
  , [("Condition", List.map f pred); ("Properties", List.map f properties)]
  )

let ask_reading =
  FC.(
    text (Conv.option Script.Reading.conv) ~placeholder:"Sign name"
    *** bool ~block:[txt "Check for attestation in composition"] ())

let form_of_simple_typ typ =
  let module M = Ty.Matcher (struct
    type 'a t = 'a FC.t
  end) in
  M.run_def
    ~default:(FC.text (Ty.Methods.conv typ))
    [M.C (Script.CompositionPattern.ty, ask_reading)]
    typ

let pred_of_list bel l =
  FC.bimap
    ~backward:(fun te ->
      match Term.unapply_builtin bel te with
      | Ok (Term.Const (_, l)) -> Ok l
      | _ -> Error "" )
    ~forward:(fun l ->
      Ok (Term.apply_builtin bel (Term.Const (Ty.List bel.inp, l))) )
    (FC.subset l)

let rec input_pred_gen : type a. a Ty.t -> (a -> bool) Term.t FC.t =
 fun ty ->
  let inverse : _ Term.t -> a Builtins._t option = function
    | Term.App (Term.Builtin b, _) -> (
      match Ty.test b.inp ty with Ok Refl -> Some (B b) | _ -> None )
    | Term.PostCompose (Term.App (Term.Builtin b, _), _) -> (
      match Ty.test b.inp ty with Ok Refl -> Some (B b) | _ -> None )
    | _ -> None
  in
  FC.choose_then ~inverse (builtins ty) (fun (B {friendly; _} as b) ->
      let p s = FC.prefix (Printf.sprintf "%s %s" friendly s) in
      match Builtins.classify b with
      | Prop b ->
          let forward x = Prelude.return (Term.compose b x)
          and backward x = Term.uncompose b x in
          p "≫" (FC.bimap ~forward ~backward (input_pred b.ret))
      | Pred b ->
          let forward x = Prelude.return (Term.apply_builtin b x)
          and backward x = Term.unapply_builtin b x in
          p ": " (FC.bimap ~forward ~backward (input_term b.param)) )

and input_pred : type a. a Ty.t -> (a -> bool) Term.t FC.t =
 fun typ ->
  let module Matcher = Ty.Matcher (struct
    type nonrec 'a t = ('a -> bool) Term.t FC.t
  end) in
  Matcher.run_def
    [ Matcher.on Ty.bool ~return:(FC.return (Term.Id Ty.bool))
    ; Matcher.on Document.Location.ty
        ~return:
          (pred_of_list Document.Location.belongs
             (Database.locations (Socket.db ())) )
    ; Matcher.on Document.Kind.ty
        ~return:
          (pred_of_list Document.Kind.belongs
             (Database.types (Socket.db ())) )
    ; Matcher.on Document.Scribe.ty
        ~return:
          (pred_of_list Document.Scribe.belongs
             (Database.scribes (Socket.db ())) )
    ; Matcher.on Document.Motif.ty
        ~return:
          (pred_of_list Document.Motif.belongs
             (Database.motifs (Socket.db ())) )
    ; Matcher.on Document.Find_spot.ty
        ~return:
          (pred_of_list Document.Find_spot.belongs
             (Database.find_spots (Socket.db ())) )
    ; Matcher.on Document.Period.ty
        ~return:
          (pred_of_list Document.Period.belongs
             (Database.periods (Socket.db ())) ) ]
    ~default:(input_pred_gen typ) typ

and input_term : type a. a Ty.t -> a Term.t FC.t = function
  | Ty.List typ ->
      let module Matcher = Ty.Matcher (struct
        type nonrec 'a t = 'a list FC.t
      end) in
      lift_const (Ty.List typ)
      @@ Matcher.run_def
           [ Matcher.on Document.Location.ty
               ~return:(FC.subset (Database.locations (Socket.db ())))
           ; Matcher.on Document.Period.ty
               ~return:(FC.subset (Database.periods (Socket.db ())))
           ; Matcher.on Document.Scribe.ty
               ~return:(FC.subset (Database.scribes (Socket.db ())))
           ; Matcher.on Document.Motif.ty
               ~return:(FC.subset (Database.motifs (Socket.db ())))
           ; Matcher.on Document.Find_spot.ty
               ~return:(FC.subset (Database.find_spots (Socket.db ())))
           ; Matcher.on Document.Kind.ty
               ~return:(FC.subset (Database.types (Socket.db ()))) ]
           ~default:(FC.return []) typ
  | typ -> lift_const typ @@ form_of_simple_typ typ

and lift_const : type a. a Ty.t -> a FC.t -> a Term.t FC.t =
 fun typ ->
  FC.bimap
    ~forward:(fun x -> Prelude.return (Term.Const (typ, x)))
    ~backward:(function
      | Term.Const (_, x) -> Prelude.return x
      | _ -> Prelude.fail "Expected constant of type %s" (Ty.show typ) )

let choose_property ~prompt ty =
  let open Builtins in
  let properties = List.filter is_property @@ of_typ ty in
  FC.choose
    ( `Placeholder prompt
    , [("", List.map (fun (B prop) -> (prop.friendly, prop.name)) properties)]
    )

let grouping_kinds ty : (string * Grouping.kind) list FC.t =
  let open Grouping in
  let choose_order =
    let f (x, y) = GroupBy (Some (x, y)) in
    FC.prefix " sorted by "
    @@ FC.choose
         ( `Default (f (`Key, Ascending))
         , [ ( ""
             , [ ("more frequent", f (`Result, Descending))
               ; ("less frequent", f (`Result, Ascending))
               ; ("alphabetic", f (`Key, Ascending)) ] ) ] )
  in
  FC.update_html (fun x -> [txt "Group results by:"; br ()] @ x @ [br ()])
  @@ FC.repeat ~numbered:true "Add subgroups"
       FC.(
         choose_property ~prompt:"Property to group on" ty *** choose_order)

let sort_kinds ty : (string * Grouping.kind) list FC.t =
  let open Grouping in
  let choose_order = FC.return (SortBy Ascending) in
  FC.update_html (fun x ->
      [txt "Sort item inside the group by:"; br ()] @ x @ [br ()] )
  @@ FC.repeat ~numbered:true "Add a property for sorting results"
       FC.(
         FC.prefix "Sort by "
         @@ choose_property ~prompt:"Property to sort on" ty
            *** choose_order)

let input_grouping_option :
    type a. a Ty.t -> (string * Grouping.kind) list FC.t =
 fun ty ->
  let forward (groups, sorts) = groups @ sorts in
  let backward list =
    List.partition
      (function _, Grouping.GroupBy _ -> true | _ -> false)
      list
  in
  FC.bimap_pure ~forward ~backward FC.(grouping_kinds ty *** sort_kinds ty)

let input_arbitrary_search_criterion :
    type a. a Ty.t -> (a -> bool) Term.t list FC.t =
 fun typ -> FC.repeat "Add an advanced criterion" (input_pred typ)

let input_settings : type a. a Ty.t -> a Search.Settings.t FC.t =
 fun typ ->
  let module Matcher = Ty.Matcher (struct
    type nonrec 'a t = 'a Search.Settings.t FC.t
  end) in
  let attestation =
    let forward whole = Prelude.return @@ Search.Settings.Attestation {whole}
    and backward = function
      | Search.Settings.Attestation {whole} -> Prelude.return whole
      | _ -> Prelude.return false
    in
    FC.bimap ~backward ~forward
    @@ FC.bool ~default:false
         ~block:[txt "Display document containing the occurrence"]
         ()
  in
  match
    Matcher.(run [on Document.Attestation.ty ~return:attestation]) typ
  with
  | Ok x -> x
  | _ -> FC.return Search.Settings.Default

let input_search : type a. a Ty.t -> a Search.t FC.t =
 fun ty ->
  let groups =
    FC.update_html
      (fun x ->
        [ enclosed ~cl:"group"
            [ txt "Displaying results"
            ; Model.help
                [ txt
                    "This section allows you to control how displays are displayed, and in particular grouped together. This allows you to see all results from a specific site for instance, or from a specific media types. Groups can be nested."
                ] ]
            x ] )
      (input_grouping_option ty)
  in
  let terms =
    let criterion =
      FC.update_html (fun s -> s @ [br ()])
      @@ input_arbitrary_search_criterion ty
    in
    FC.update_html
      (fun x ->
        [ enclosed ~cl:"search"
            [ txt "Search criterion"
            ; Model.help
                [ txt
                    "This section allows you to enter the criteria for your search. Only entries that match ALL criteria will be displayed."
                ] ]
            x ] )
      FC.(criterion *** input_settings ty)
  in
  FC.bimap
    ~backward:(fun {Search.criteria; settings; grouping; _} ->
      Prelude.return ((criteria, settings), grouping) )
    ~forward:(fun ((criteria, settings), grouping) ->
      Prelude.return {Search.typ = ty; criteria; settings; grouping} )
    FC.(terms *** groups)

let ask_search ty search f =
  FC.run (input_search ty)
    ~cl:["button"; "search-button"]
    search ~button:"Search the corpus !" f
