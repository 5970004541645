open Prelude

type t = float * float * float [@@deriving yojson]

let of_string s =
  try Scanf.sscanf s "%gx%gx%g" (fun a b c -> return (a, b, c))
  with _ -> fail "Invalid size: %s" s

let width (a, _, _) = a

let height (_, b, _) = b

let depth (_, _, c) = c

let show (a, b, c) = Printf.sprintf "%g cm × %g cm × %g cm" a b c

type 'a Ty.custom += Size : t Ty.custom

let cast (type a) : a Ty.custom -> (t, a) Equality.t option = function
  | Size -> Some Equality.Refl
  | _ -> None

let ty : t Ty.t =
  Ty.Custom.add ~friendly:"Size of an inscription (3D)" "size"
