include Angstrom

let ( >> ) m m' = m >>= fun _ -> m'

let ident =
  take_while1 (function
    | '?' | '-' | 'a' .. 'z' | 'A' .. 'Z' -> true
    | _ -> false )

let trim = skip_while (fun x -> x = ' ')

let keyword s = trim >> string s >> trim

let strings = function
  | [] -> fail ""
  | t :: q -> List.fold_left (fun p s -> p <|> string s) (string t) q

let strings_assoc = function
  | [] -> fail ""
  | (s, v) :: q ->
      List.fold_left
        (fun p (s, v) -> p <|> (string s >> return v))
        (string s >> return v)
        q

let int =
  take_while1 (function '0' .. '9' -> true | _ -> false) >>| int_of_string

let delim start close tm =
  keyword start >> tm >>= fun v -> keyword close >> return v

let parens x = delim "(" ")" x

let square x = delim "[" "]" x

let pair ?(start = "(") ?(stop = ")") ?(sep = ",") p1 p2 =
  delim start stop
    (p1 >>= fun x -> keyword sep >> p2 >>= fun y -> return (x, y))

let prefix ?error l =
  choice ?failure_msg:error (List.map (fun (s, p) -> string s >> p) l)

let delay f = return () >>= f

let run p s = parse_string ~consume:Consume.All p s

let chainl1 e op =
  let rec go acc = lift2 (fun f x -> f acc x) op e >>= go <|> return acc in
  e >>= fun init -> go init

let float =
  take_while1 (function '0' .. '9' | '.' -> true | _ -> false)
  >>| Float.of_string

let input = take_while1 (fun _ -> true)

let option p = p >>| (fun x -> Some x) <|> (string "" >> return None)
