type ('param, 'inp, 'out) t =
  { name: string
  ; friendly: string
  ; eval: 'param -> 'inp -> 'out
  ; description: string option
  ; param: 'param Ty.t
  ; ret: 'out Ty.t
  ; inp: 'inp Ty.t }

let eval b = b.eval

type 'inp _t = B : ('param, 'inp, 'out) t -> 'inp _t

type untyped = U : ('param, 'inp, 'out) t -> untyped

module SMap = Map.Make (String)

let custom = ref SMap.empty

let make ?description ~friendly ~eval ~param ~inp ~ret name =
  B {name; eval; param; friendly; ret; inp; description}

let register_ ?description ~friendly ~eval ~param ~inp ~ret name =
  let builtin = {name; friendly; eval; param; ret; inp; description} in
  custom :=
    SMap.update (Ty.show inp)
      (function None -> Some [U builtin] | Some l -> Some (U builtin :: l))
      !custom ;
  builtin

let register ?description ~friendly ~eval ~param ~inp ~ret name =
  ignore @@ register_ ?description ~friendly ~eval ~param ~inp ~ret name

let typ_of b = Ty.(b.param --> (b.inp --> b.ret))

let belongs ty =
  register_ ~friendly:"Is in"
    ("belongs-" ^ Ty.show ty)
    ~param:(Ty.List ty) ~inp:ty ~ret:Ty.bool
    ~eval:(fun l x -> l = [] || List.mem x l)

let list ty _ =
  [ make "Length"
      ~inp:Ty.(list ty)
      ~param:Ty.unit ~friendly:"List length"
      ~eval:(fun _ l -> List.length l)
      ~ret:Ty.int
  ; make ~friendly:"Contains one element satisfying" "exists"
      ~inp:Ty.(list ty)
      ~param:Ty.(ty --> bool)
      ~ret:Ty.bool
      ~eval:(fun pred l -> List.exists pred l)
  ; make ~friendly:"Contains only elements satisfying" "forall"
      ~inp:Ty.(list ty)
      ~param:Ty.(ty --> bool)
      ~ret:Ty.bool
      ~eval:(fun pred l -> List.for_all pred l)
  ; make ~friendly:"Contains no elements satisfying" "no"
      ~inp:Ty.(list ty)
      ~param:Ty.(ty --> bool)
      ~ret:Ty.bool
      ~eval:(fun pred l -> not @@ List.exists pred l) ]

let rec of_typ : type a. a Ty.t -> a _t list = function
  | Ty.List ty -> list ty (of_typ ty)
  | Pair (_, _) -> []
  | Arrow (_, _) -> []
  | Custom (s, _) as ty -> (
    try
      let l = SMap.find s !custom in
      List.filter_map
        (fun (U b) ->
          match Ty.test ty b.inp with
          | Ok Refl -> Some (B b : a _t)
          | _ -> None )
        l
    with _ -> [] )

let find_by_name_untyped name =
  let r = ref [] in
  let _ =
    SMap.exists
      (fun _ l ->
        List.exists
          (fun (U b) ->
            if b.name = name then (
              r := U b :: !r ;
              true )
            else false )
          l )
      !custom
  in
  match !r with
  | [x] -> x
  | _ -> failwith @@ Printf.sprintf "Ambiguous bindings for %s" name
  | exception Not_found ->
      failwith @@ Printf.sprintf "No binding for %s" name

let find_by_name name' typ =
  try List.find (fun (B {name; _}) -> name = name') (of_typ typ)
  with Not_found ->
    failwith
    @@ Printf.sprintf "Unknown builtin %s of type %s" name' (Ty.show typ)

let () =
  register ~friendly:"Greater or equal to" "geq" ~inp:Ty.int ~ret:Ty.bool
    ~param:Ty.int ~eval:(fun param value -> value >= param) ;
  register ~friendly:"Less or equal than" "leq" ~inp:Ty.int ~ret:Ty.bool
    ~param:Ty.int ~eval:(fun param value -> value <= param) ;
  register ~friendly:"Between" "between" ~inp:Ty.int ~ret:Ty.bool
    ~param:Ty.(int *** int)
    ~eval:(fun (param1, param2) value -> param1 <= value && value <= param2) ;
  register ~friendly:"Greater or equal to" "geq" ~inp:Ty.float ~ret:Ty.bool
    ~param:Ty.float ~eval:(fun param value -> value >= param) ;
  register ~friendly:"Less or equal than" "leq" ~inp:Ty.float ~ret:Ty.bool
    ~param:Ty.float ~eval:(fun param value -> value <= param) ;
  register ~friendly:"Between" "between" ~inp:Ty.float ~ret:Ty.bool
    ~param:Ty.(float *** float)
    ~eval:(fun (param1, param2) value -> param1 <= value && value <= param2) ;
  register ~friendly:"Equals to" "eq" ~inp:Ty.string ~ret:Ty.bool
    ~param:Ty.string ~eval:( = )

type 'inp classification =
  | Pred : ('param, 'inp, bool) t -> 'inp classification
  | Prop : (unit, 'inp, 'out) t -> 'inp classification

let classify (B b) =
  match Ty.(test b.param Ty.unit) with
  | Ok Refl -> Prop b
  | _ -> (
    match Ty.(test b.ret Ty.bool) with
    | Ok Refl -> Pred b
    | Error _ ->
        failwith @@ Printf.sprintf "Builtin %s cannot be classified" b.name )

let is_property b = match classify b with Pred _ -> false | _ -> true

(*let location = Ty.Custom.add ~conv:Conv.string "location"

  let kind = Ty.Custom.add ~conv:Conv.string "document type"*)

(*let match_word = _make "Matching expression" ~inp:Document.Sequence.ty
  ~ret:Ty.bool ~param:Regexp.Word.typ ~description: "Whether the
  transcription of the word matches the following \ expression. An expression
  is a hyphen-separated list of sign \ descriptions, with a possible hyphen
  at the beginning or at the end \ to indicate partial matching: 'a' matches
  the word with one sign, \ 'a'; while '-a-' matches any word containing the
  sign 'a'; and '-a' \ words that end in a. It is also possible to use '?' in
  lieu of a \ sign to mean 'any sign'; and '*' to mean 'any sequence of
  sign': \ 'a-*-a' means any word starting and ending by 'a'."
  ~eval:Regexp.Word.matches

  let location_word = _make "Found at" ~inp:Document.Sequence.ty
  ~param:Ty.(list location) ~description: "Site where the document containing
  the attestation was found" ~ret:Ty.bool ~eval:(fun l word -> l = [] ||
  List.mem (Document.Sequence.document word).Document.location l)

  let kind_word = _make "Found on" ~inp:Document.Sequence.ty ~param:Ty.(list
  kind) ~description:"Document types where to search" ~ret:Ty.bool ~eval:(fun
  l word -> l = [] || List.mem (Document.Sequence.document
  word).Document.kind l)

  let word = [ make "Occurrences of the word" ~inp:Document.Sequence.ty
  ~ret:Ty.(list Sign.Attestation.typ) ~param:Ty.unit ~eval:(fun db _ word ->
  let doc = Database.find_document db word.Word.document in List.map
  (Document.at doc) word.Word.signs) ; B location_word ; B kind_word ; make
  "Document" ~inp:Document.Sequence.ty ~ret:Document.typ ~param:Ty.unit
  ~eval:(fun db _ w -> Database.find_document db w.Word.document) ; make
  "Word length" ~inp:Document.Sequence.ty ~ret:Ty.int ~param:Ty.unit
  ~eval:(fun _ _ word -> List.length word.Word.signs) ; make "Document"
  ~inp:Document.Sequence.ty ~ret:Document.typ ~param:Ty.unit ~eval:(fun db _
  word -> Database.find_document db word.Word.document) ; make "Site"
  ~inp:Document.Sequence.ty ~ret:Ty.string ~param:Ty.unit ~eval:(fun db _
  word -> (Database.find_document db word.Word.document).location) ; B
  match_word ]

  let location_doc = _make "Found at" ~inp:Document.typ ~param:Ty.(list
  location) ~description:"Site where the document was found" ~ret:Ty.bool
  ~eval:(fun _ l x -> l = [] || List.mem x.Document.location l)

  let kind_doc = _make "Document type" ~inp:Document.typ ~param:Ty.(list
  kind) ~ret:Ty.bool ~eval:(fun _ l x -> l = [] || List.mem x.Document.kind
  l)

  let document = [ B location_doc ; B kind_doc ; make "Number of signs"
  ~inp:Document.typ ~param:Ty.unit ~ret:Ty.int ~eval:(fun _ _ doc ->
  Array.length doc.Document.signs) ; make "Number of words" ~inp:Document.typ
  ~param:Ty.unit ~ret:Ty.int ~eval:(fun _ _ doc -> Array.length
  doc.Document.words) ; make "Site" ~inp:Document.typ ~param:Ty.unit
  ~ret:Ty.string ~eval:(fun _ _ doc -> doc.Document.location) ; make "Width"
  ~inp:Document.typ ~param:Ty.(float --> bool) ~ret:Ty.bool
  ~description:"Width of the physical document (cm)" ~eval:(fun _ pred doc ->
  match doc.Document.size with | None -> false | Some size -> pred
  (Document.Size.width size)) ; make "Depth" ~inp:Document.typ
  ~param:Ty.(float --> Ty.bool) ~ret:Ty.bool ~description:"Depth of the
  physical document (cm)" ~eval:(fun _ pred doc -> match doc.Document.size
  with | None -> false | Some size -> pred (Document.Size.depth size)) ; make
  "Height" ~inp:Document.typ ~param:Ty.(float --> Ty.bool) ~ret:Ty.bool
  ~description:"Height of the physical document (cm)" ~eval:(fun _ pred doc
  -> match doc.Document.size with | None -> false | Some size -> pred
  (Document.Size.height size)) ; make "Name" ~inp:Document.typ ~param:Ty.unit
  ~ret:Ty.string ~eval:(fun _ _ doc -> doc.Document.name) ; make "Type"
  ~inp:Document.typ ~param:Ty.unit ~ret:Ty.string ~description:"Type of
  document (Tablet, Label, ...)" ~eval:(fun _ _ doc -> doc.Document.kind) ;
  make "Contains one occurrence satisfying" ~inp:Document.typ
  ~param:Ty.(Sign.Attestation.typ --> bool) ~ret:Ty.bool ~eval:(fun _ pred
  doc -> Array.exists pred doc.Document.signs) ; make "Contains one word
  satisfying" ~inp:Document.typ ~param:Ty.(Document.Sequence.ty --> bool)
  ~ret:Ty.bool ~eval:(fun _ pred doc -> Array.exists pred doc.Document.words)
  ; make "Occurrences of signs on the tablet" ~inp:Document.typ
  ~param:Ty.unit ~ret:Ty.(list Sign.Attestation.typ) ~eval:(fun _ _ doc ->
  Array.to_list doc.Document.signs) ; make "Words on the tablet"
  ~inp:Document.typ ~param:Ty.unit ~ret:Ty.(list Document.Sequence.ty)
  ~eval:(fun _ _ doc -> Array.to_list doc.Document.words) ]

  let location_occ = _make "Found at" ~inp:Sign.Attestation.typ
  ~param:Ty.(list location) ~description: "Site where the document containing
  the attestation was found" ~ret:Ty.bool ~eval:(fun db l x -> l = [] ||
  List.mem (Database.find_document db x.Sign.Attestation.document)
  .Document.location l)

  let kind_occ = _make "Found on" ~inp:Sign.Attestation.typ ~param:Ty.(list
  kind) ~ret:Ty.bool ~eval:(fun db l x -> l = [] || List.mem
  (Database.find_document db x.Sign.Attestation.document) .Document.kind l)

  let attestation_occ = _make "Attestation of sign" ~inp:Sign.Attestation.typ
  ~param:Composition.Pattern.typ ~description: "The occurrence reads to the
  following sign. Signs can either be \ specified using their fullname, eg.
  'AB21'; their phonetic values, \ eg. qi; or latin transcription
  (uppercase), eg. OVIS. By default, \ all variants of the sign are matched
  unless a particular variant is \ specified by adding it to the end: AB21f,
  qif and OVISf all match \ the female variant of AB21.\n\n\ You can also
  look for composite signs by using the syntax described \ in Help."
  ~ret:Ty.bool ~eval:(fun _ pattern x -> Composition.Pattern.match_ pattern
  x.Sign.Attestation.reading)

  let attestation = let open Sign.Attestation in [ make "Is unreadable"
  ~inp:Sign.Attestation.typ ~param:Ty.unit ~ret:Ty.bool ~eval:(fun _ _ x ->
  x.reading = Unreadable) ; make "Is unclassified" ~inp:Sign.Attestation.typ
  ~param:Ty.unit ~ret:Ty.bool ~eval:(fun _ _ x -> x.reading = Unclassified) ;
  make "Is an attestation of a composite sign" ~inp:Sign.Attestation.typ
  ~param:Ty.unit ~ret:Ty.bool ~eval:(fun _ _ x -> match x.reading with |
  Sign.Reading.Readable {instance; _} -> instance.meta.composition <> None |
  _ -> false) ; make "Is an incorporated sign" ~inp:Sign.Attestation.typ
  ~param:Ty.unit ~ret:Ty.bool ~eval:(fun _ _ x -> match x.reading with |
  Sign.Reading.Readable {instance; _} -> ( match instance.meta.composition
  with | Some s -> ( match Parsing.run Composition.parse_sign s with | Ok
  (Composition.Fuse _, _) -> true | _ -> false ) | _ -> false ) | _ -> false)
  ; make "Site" ~inp:Sign.Attestation.typ ~ret:Ty.string ~param:Ty.unit
  ~eval:(fun db _ occ -> (Database.find_document db occ.document).location) ;
  make "Document" ~inp:Sign.Attestation.typ ~ret:Document.typ ~param:Ty.unit
  ~eval:(fun db _ occ -> Database.find_document db occ.document) ; make
  "Function" ~inp:Sign.Attestation.typ ~param:Ty.unit ~ret:Sign.Role.typ
  ~eval:(fun _ _ x -> x.role) ; make "Is a logogram"
  ~inp:Sign.Attestation.typ ~param:Ty.unit ~ret:Ty.bool ~eval:(fun _ _ x ->
  fst x.role = Logogram) ; make "Is a transaction sign"
  ~inp:Sign.Attestation.typ ~param:Ty.unit ~ret:Ty.bool ~eval:(fun _ _ x ->
  fst x.role = TransactionSign) ; make "Is an erasure"
  ~inp:Sign.Attestation.typ ~param:Ty.unit ~ret:Ty.bool ~eval:(fun _ _ x ->
  x.erasure = true) ; B location_occ ; make "Has unsure reading"
  ~inp:Sign.Attestation.typ ~param:Ty.unit ~ret:Ty.bool ~eval:(fun _ _ x ->
  match x.reading with | Readable {confidence; _} -> not confidence | _ ->
  false) ; B attestation_occ ; B kind_occ ; make "Image Width (pixels)"
  ~inp:Sign.Attestation.typ ~ret:Ty.int ~param:Ty.unit ~eval:(fun _ _ occ ->
  occ.position.Rect.w) ; make "Image Height (pixels)"
  ~inp:Sign.Attestation.typ ~ret:Ty.int ~param:Ty.unit ~eval:(fun _ _ occ ->
  occ.position.Rect.h) ; make "Reads to a sign instance"
  ~inp:Sign.Attestation.typ ~param:Ty.(Sign.Instance.typ --> bool)
  ~ret:Ty.bool ~description: "If the occurrence is readable, and satifies the
  following criterion" ~eval:(fun _ pred x -> match x.reading with | Readable
  {instance; _} -> pred instance | _ -> false) ]

  let funct = []

  let instance = [ make ~inp:Sign.Instance.typ "Is an instance of"
  ~param:Ty.string ~ret:Ty.bool ~eval:(fun _ _ _ -> true) ; make "Of sign"
  ~inp:Sign.Instance.typ ~param:Ty.unit ~ret:Sign.Meta.typ ~eval:(fun _ _ x
  -> x.Sign.meta) (* ; make "Variant" ~inp:Ty.Attestation ~param:Ty.unit
  ~ret:Ty.string ~eval:(fun _ _ x -> x.Attestation.variant)*) ]

  let sign = []

  let rec of_typ : type a. a Ty.t -> a _t list = function | Ty.List t -> list
  t (of_typ t) | Ty.Custom (_, Ty.String) -> string | Ty.Custom (_, Ty.Float)
  -> float | Ty.Custom (_, Ty.Int) -> int | Ty.Custom (_, Document.Document)
  -> document | Ty.Custom (_, Sign.Attestation.SignAttestation) ->
  attestation | Ty.Custom (_, Sign.Instance.SignInstance) -> instance |
  Ty.Custom (_, Sign.Meta.SignMeta) -> sign | Ty.Custom (_,
  Word.WordAttestation) -> word | Ty.Custom (_, Sign.Role.Role) -> funct | _
  -> []

  let find_by_name name' typ = List.find (fun (B {name; _}) -> name = name')
  (of_typ typ)

  let typ_of b = Ty.(b.param --> (b.inp --> b.ret)) *)
