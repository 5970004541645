open Core

type sign =
  { prefix: string
  ; number: int
  ; phonetic: PhoneticValue.t option [@key "value"] [@default None]
  ; rep: string option [@default None]
  ; latin: string option [@default None]
  ; variants: (string * string) list [@default []]
  ; vessel: bool [@default false]
  ; comment: (string * string) option [@default None]
  ; composition: string option [@default None] }

and instance = {sign: sign; variant: string option [@default None]}

and reading =
  | Unreadable
  | Unclassified
  | Readable of {instance: instance; confidence: bool}

and composition = reading Composition.t [@@deriving yojson]

module DB = struct
  module M = struct
    include Map.Make (Int)

    let of_yojson _ s =
      match [%of_yojson: sign list] s with
      | Error e -> Error e
      | Ok l -> Ok (List.fold_left (fun m s -> add s.number s m) empty l)

    let to_yojson _ s = [%to_yojson: sign list] (List.map snd @@ bindings s)
  end

  type t = {signs: sign M.t; prefixes: string list} [@@deriving yojson]

  let sign_list = ref {signs = M.empty; prefixes = []}

  let aliases = ref []

  let set x =
    sign_list := x ;
    M.iter
      (fun _ meta ->
        ( match meta.phonetic with
        | Some ph -> aliases := (PhoneticValue.show ph, meta) :: !aliases
        | None -> () ) ;
        match meta.latin with
        | Some s -> aliases := (s, meta) :: !aliases
        | None -> () )
      x.signs ;
    aliases :=
      List.sort
        (fun (x, _) (y, _) -> compare (String.length y) (String.length x))
        !aliases

  let load_from_json x = Prelude.(of_yojson x >|= set)

  let load file = Prelude.(report_error (json_of_file load_from_json file))

  let load_from_string string = set (Marshal.from_string string 0)

  let to_string () = Marshal.to_string !sign_list []

  let find_by_number ?(prefix = "A") n =
    try M.find n !sign_list.signs
    with Not_found ->
      { number = n
      ; prefix
      ; rep = None
      ; vessel = false
      ; phonetic = None
      ; latin = None
      ; comment = None
      ; variants = []
      ; composition = None }
end

module Sign = struct
  type t = sign [@@deriving yojson]

  let parse =
    let open Parsing in
    let star =
      let* n = Parsing.int in
      let sign = DB.find_by_number n in
      if sign.vessel then prefix [("VAS", return sign); ("", return sign)]
      else return sign
    in
    let with_prefix pre =
      let* number = Parsing.int in
      let sign = DB.find_by_number ~prefix:pre number in
      if sign.vessel then prefix [("VAS", return sign); ("", return sign)]
      else return sign
    in
    delay (fun () ->
        prefix
          ( List.map (fun s -> (s, with_prefix s)) !DB.sign_list.prefixes
          @ List.map (fun (alias, data) -> (alias, return data)) !DB.aliases
          @ [("*", star)] ) )
    <|> fail "Invalid sign name"

  let show t =
    if t.number < 0 then "" else t.prefix ^ Printf.sprintf "%02d" t.number

  let conv = Conv.make ~show ~parse

  let show_full t =
    if t.number < 0 then ""
    else
      match (t.latin, t.phonetic) with
      | None, None -> show t
      | Some s, None -> Printf.sprintf "%s (%s)" (show t) s
      | None, Some ph ->
          Printf.sprintf "%s/%s" (show t) (PhoneticValue.show ph)
      | Some s, Some ph ->
          Printf.sprintf "%s/%s (%s)" (show t) (PhoneticValue.show ph) s

  let show_syllabogram t =
    match t.phonetic with
    | Some ph -> PhoneticValue.show ph
    | _ when t.prefix = "AB" -> Printf.sprintf "*%02d" t.number
    | _ -> show t

  let ty = Ty.Custom.add ~friendly:"Linear A sign" "sign" ~conv

  let instances sign =
    List.map
      (fun variant -> {sign; variant})
      (None :: List.map (fun (x, _) -> Some x) sign.variants)

  let list () = List.map snd @@ DB.M.bindings @@ !DB.sign_list.signs

  let default_role x =
    if x.number >= 701 && x.number <= 713 then
      (Role.Fraction, Uncertainty.Sure)
    else (Role.Unknown, Uncertainty.Sure)
end

module Instance = struct
  type t = instance [@@deriving yojson]

  let list () = List.concat @@ List.map Sign.instances @@ Sign.list ()

  let show ?(f = Sign.show) {sign; variant} =
    f sign ^ Option.value ~default:"" variant

  let parse =
    let open Parsing in
    let* sign = Sign.parse in
    prefix
      ( List.map
          (fun (s, _) -> (s, return {sign; variant = Some s}))
          sign.variants
      @ [("", return {sign; variant = None})] )

  let conv = Conv.make ~show ~parse

  let ty = Ty.Custom.add ~friendly:"Linear A sign variant" ~conv "instance"

  let matches a b =
    a.sign.number = b.sign.number
    && match a.variant with Some x -> b.variant = Some x | _ -> true
end

module Reading = struct
  type t = reading [@@deriving yojson]

  let show ?f = function
    | Unreadable -> "[?]"
    | Unclassified -> "unclassified"
    | Readable {instance; confidence} ->
        Instance.show ?f instance ^ if confidence then "" else "?"

  let parse =
    let open Parsing in
    strings ["unreadable"; "?"; "[?]"]
    >> return Unreadable
    <|> (string "unclassified" >> return Unclassified)
    <|> (let* instance = Instance.parse in
         let* confidence = prefix [("?", return false); ("", return true)] in
         return @@ Readable {instance; confidence})
    <|> fail "Invalid sign name"

  let conv = Conv.make ~show ~parse

  let ty = Ty.Custom.add ~friendly:"Linear A sign reading" ~conv "reading"

  let matches pattern reading =
    match (pattern, reading) with
    | Unreadable, Unreadable -> true
    | Unclassified, Unclassified -> true
    | Readable {instance = a; _}, Readable {instance = a'; _} ->
        Instance.matches a a'
    | _ -> false

  let sign = function
    | Readable {instance = {sign; _}; _} -> Some sign
    | _ -> None
end

let composition ({composition; _} as sign) =
  match composition with
  | None -> None
  | Some s -> (
    match Parsing.run (Composition.parse Reading.parse) s with
    | Ok v -> Some v
    | Error e ->
        Printf.eprintf "Sign %s: Cannot parse composition %s: %s\n"
          (Sign.show sign) s e ;
        None )

module CompositionPattern = struct
  type t = Reading.t option * bool

  let conv = Conv.(pair (option Reading.conv) bool)

  let parse = conv.parse

  let ty = Ty.Custom.add ~friendly:"Reading pattern" ~conv "reading-pattern"

  let matches (pattern, in_composition) reading =
    match (pattern, in_composition, reading) with
    | None, _, _ -> true
    | Some r, false, _ -> Reading.matches r reading
    | Some (Unreadable | Unclassified), true, _ -> false
    | Some (Readable _ as r), true, Readable {instance; _} -> (
      match composition instance.sign with
      | None -> false
      | Some (x, _) -> Composition.contains r Reading.matches x )
    | _, _, _ -> false
end
