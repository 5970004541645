let root = ref ""

let ( // ) = Filename.concat

let _make s = if !root = "" then s else !root // s

let make dir s = _make (dir // s)

let make_dir dir s = _make (dir // (s ^ "/"))

let location = make_dir "location"

let kind = make_dir "kind"

let period = make_dir "period"

let document ?(wordview = false) ?selected doc =
  let s = LinearA.Document.name doc in
  match (wordview, selected) with
  | false, None -> _make (("document" // s) ^ "/")
  | false, Some k ->
      _make ("document" // s // Printf.sprintf "index-%d.html" k)
  | true, None -> _make ("document" // s // "index-word.html")
  | true, Some k ->
      _make ("document" // s // Printf.sprintf "index-word-%d.html" k)

let document_image doc = _make (LinearA.Document.image doc)

let occ_image occ = _make (LinearA.Document.Attestation.image occ)

let with_root x f =
  let r = !root in
  root := x ;
  let v = f () in
  root := r ;
  v

let ressource x = _make x

let occurrence occ =
  document ~selected:occ.LinearA.Document.number
    (LinearA.Document.Attestation.document occ)

let sequence wo =
  document ~wordview:true ~selected:wo.LinearA.Document.index
    (LinearA.Document.Sequence.document wo)

let search_sign () = _make "search-attestation.html"

let search_document () = _make "search-document.html"

let search_sequence () = _make "search-sequence.html"

let map () = _make "map.html"

let browse () = _make "browse.html"

let sign_list () = _make "sign-list.html"

let database_javascript () = _make "database.js"

let javascript () = _make "sigilWeb.bc.js"

let search_results s =
  let (LinearA.Search.Search {typ; _}) = s in
  _make ("search-" ^ Core.Ty.show typ ^ ".html#" ^ LinearA.Search.show s)

let instance i =
  search_results LinearA.Search.(sign ~sign:(pattern_of_instance i) ())

let sign i =
  search_results LinearA.Search.(sign ~sign:(pattern_of_meta i) ())

let word w =
  search_results
    LinearA.Search.(
      word ~exp:(LinearA.Document.SequencePattern.of_word w) ())
