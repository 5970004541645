type 'a token = Char of 'a | Star | QuestionMark [@@deriving yojson]

type 'a t = 'a token list [@@deriving yojson]

let split c s =
  let get i j = String.sub s i (j - i) in
  let rec aux acc last i =
    if i = String.length s then List.rev (get last i :: acc)
    else if s.[i] = c then aux (get last i :: acc) (i + 1) (i + 1)
    else aux acc last (i + 1)
  in
  aux [] 0 0

let show_token s = function
  | Char c -> s c
  | Star -> "*"
  | QuestionMark -> "?"

let show s l = String.concat "-" @@ List.map (show_token s) l

let parse item =
  let open Parsing in
  let basic =
    string "*" >> return Star
    <|> (string "?" >> return QuestionMark)
    <|> (item >>| fun x -> Char x)
    <|> (string "" >> return Star)
  in
  sep_by1 (keyword "-") basic

let conv c = Conv.make ~show:(show c.Conv.show) ~parse:(parse c.Conv.parse)

(* Tests if r1 \subseteq r2 *)
let subset match_ =
  let rec aux r1 r2 =
    match (r1, r2) with
    | x, [] -> x = []
    | [], l -> List.for_all (( = ) Star) l
    | Char c :: rest, Char c' :: rest' -> match_ c c' && aux rest rest'
    | Char _ :: rest, QuestionMark :: rest' -> aux rest rest'
    | (Char _ | QuestionMark) :: rest, Star :: rest' ->
        aux rest rest' || aux r1 rest' || aux rest (Star :: rest')
    | QuestionMark :: _, Char _ :: _ -> false
    | QuestionMark :: rest, QuestionMark :: rest' -> aux rest rest'
    | Star :: rest, Star :: rest' -> aux rest rest' || aux rest r2
    | Star :: _, _ :: _ -> false
  in
  aux
