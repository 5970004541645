open Core
open LinearA
open Js_of_ocaml

let port = 4343

let compute_url () =
  let host = Js.to_string @@ Dom_html.window##.location##.hostname in
  let protocol = match host with _ -> "ws" in
  Printf.sprintf "%s://%s:%d/" protocol host port

let password = ref None

let is_logged_in () = !password <> None

let ask question callback =
  let sock = new%js WebSockets.webSocket (Js.string (compute_url ())) in
  sock##.onopen :=
    Dom.handler (fun _ ->
        sock##send
          (Js.string
             (Protocol.string_of_request
                {Protocol.question; auth = !password} ) ) ;
        Js._false ) ;
  sock##.onmessage :=
    Dom.handler (fun event ->
        let s = Js.to_string event##.data in
        ( match Protocol.reply_of_string s question with
        | Ok (Protocol.Ok v) -> callback (Ok v)
        | Ok (Protocol.Error e) -> callback (Error e)
        | Error e -> callback (Error e) ) ;
        sock##close ; Js._false )

let _db = ref None

let load () =
  Printf.printf "Loading sign database...\n" ;
  Script.DB.load_from_string @@ Prelude.js_decode_string @@ Js.to_string
  @@ Js.Unsafe.variable "signs"

let db () =
  match !_db with
  | None ->
      let db =
        Database.of_string @@ Js.to_string @@ Js.Unsafe.variable "data"
      in
      _db := Some db ;
      db
  | Some x -> x

let () = Printf.printf "Done !\n"

(* match !db with | Some db -> f db | None -> ( waiting () ; match
   Database.of_yojson @@ Yojson.Safe.from_string @@ Js.to_string @@
   Js.Unsafe.variable "data" with | Ok new_db -> db := Some new_db ; f new_db
   | Error _ -> () )*)

(*let perform x = ask x (function | Ok () -> () | Error e ->
  H.Notification.error "When transmitting the request to the server: %s" e ;
  failwith "") *)
let login s =
  (* perform (Protocol.Auth s) ;*)
  password := Some s
