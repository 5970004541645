open Model
open Core
open M.Html
open Js_of_ocaml

type block = Html_types.div_content elt

type inline = Html_types.span_content elt

type 'a instance = {block: block list; get: unit -> 'a option}

type 'a t = F of ('a option -> 'a instance)

(* Running *)
let create init (F f) = f init

let draw {block; _} = block

let get {get; _} = get ()

let id = ref 0

let prefix' prefix (F f) =
  F
    (fun x ->
      let v = f x in
      {v with block = prefix () @ v.block} )

let prefix prefix x = prefix' (fun () -> [txt prefix]) x

let update_html up (F f) =
  F
    (fun x ->
      let v = f x in
      {v with block = up v.block} )

let return ?(block = []) elem =
  F (fun _ -> {block; get = (fun () -> Some elem)})

let empty = return ()

let bimap ~forward ~backward (F create) =
  F
    (fun init ->
      let init' =
        match init with
        | None -> None
        | Some x -> Stdlib.Result.to_option (backward x)
      in
      let instance = create init' in
      { instance with
        get =
          (fun () ->
            match instance.get () with
            | None -> None
            | Some v -> Stdlib.Result.to_option (forward v) ) } )

let bimap_pure ~forward ~backward =
  bimap
    ~forward:(fun x -> Ok (forward x))
    ~backward:(fun x -> Ok (backward x))

let block block = F (fun _ -> {block; get = (fun () -> Some ())})

let ( *** ) ?(sep = []) (F c1) (F c2) =
  F
    (fun init ->
      let i1 = c1 (Option.map fst init) and i2 = c2 (Option.map snd init) in
      { block = i1.block @ sep @ i2.block
      ; get =
          (fun () ->
            match (i1.get (), i2.get ()) with
            | Some x, Some y -> Some (x, y)
            | _, _ -> None ) } )

let list ?(sep = []) list =
  F
    (fun init ->
      ignore sep ;
      let init =
        match init with
        | Some x -> List.map (fun x -> Some x) x
        | None -> List.init (List.length list) (fun _ -> None)
      in
      let instances =
        List.map2 (fun (F create) init -> create init) list init
      in
      let block = List.(concat (map (fun {block; _} -> block) instances)) in
      let get () =
        try Some (List.map (fun {get; _} -> Option.get (get ())) instances)
        with _ -> None
      in
      {block; get} )

(* Basic types *)
let bool ?(default = false) ?(block = []) () =
  F
    (fun init ->
      let id = "_" ^ string_of_int (incr id ; !id) in
      let init = Option.value init ~default in
      let checkbox =
        input
          ~a:
            ( [a_id id; a_input_type `Checkbox]
            @ if init then [a_checked ()] else [] )
          ()
      in
      let block = [label ~a:[a_label_for id] [checkbox; span block]] in
      let get () = Some (Js.to_bool (Model.cast_input checkbox)##.checked) in
      {block; get} )

let text ?(placeholder = "") ?(block = ([] : inline list)) conv =
  F
    (fun init ->
      let id = "_" ^ string_of_int (incr id ; !id) in
      let init = Option.(value (map conv.Conv.show init) ~default:"") in
      let input =
        input
          ~a:
            [ a_id id
            ; a_placeholder placeholder
            ; a_input_type `Text
            ; a_value init ]
          ()
      in
      let err = span ~a:[a_class ["form-error"]] [] in
      let block = [label ~a:[a_label_for id] [span block; input; err]] in
      let get () =
        let v = Js.to_string (Model.cast_input input)##.value in
        match Core.Conv.parse conv v with
        | Ok v -> Some v
        | Error s ->
            Model.set_content err M.Html.[txt ("⚠ " ^ s)] ;
            None
      in
      {get; block} )

let subset possibilities =
  let widget possibility =
    bimap_pure
      ~forward:(fun b -> if b then [possibility] else [])
      ~backward:(fun l -> l <> [])
      (bool ~block:[txt possibility] ())
  in
  bimap_pure
    (list (List.map widget possibilities))
    ~forward:List.concat
    ~backward:(fun list ->
      List.map (fun x -> if List.mem x list then [x] else []) possibilities
      )

let repeat ?(numbered = false) text (m : 'a t) : 'a list t =
  F
    (fun init ->
      let widgets = ref [||] in
      let get () =
        try
          Some
            Array.(
              to_list @@ map (fun {get; _} -> Option.get (get ())) !widgets)
        with _ -> None
      in
      let block = div [] in
      let rec remove i () =
        let bef = Array.sub !widgets 0 i in
        let aft =
          if i = Array.length !widgets - 1 then [||]
          else Array.sub !widgets (i + 1) (Array.length !widgets - i - 1)
        in
        widgets := Array.append bef aft ;
        redraw ()
      and add () =
        widgets := Array.append !widgets [|create None m|] ;
        redraw ()
      and redraw () =
        let draw i {block; _} =
          li
            ( block
            @ [ txt " "
              ; action ~cl:["button"] ~title:"Remove this item" (remove i)
                  [txt " - "] ] )
        in
        let items =
          (if numbered then ol else ul)
          @@ Array.(to_list @@ mapi draw !widgets)
        in
        Model.set_content block
          [items; action ~cl:["button"] ~title:text add [txt " + "]]
      in
      let () =
        match init with
        | None -> ()
        | Some l ->
            widgets :=
              Array.of_list @@ List.map (fun x -> create (Some x) m) l
      in
      redraw () ;
      {block = [block]; get} )

(* Choices *)
type 'a choice =
  [`Placeholder of string | `Default of 'a]
  * (string * (string * 'a) list) list

let choose_then ~inverse ((prompt, choices) : 'a choice) after =
  F
    (fun init ->
      let div = span [] in
      let update blocks = set_content div blocks in
      let instance =
        match Option.bind init inverse with
        | Some value -> ref (Some (create init (after value)))
        | _ -> ref None
      in
      let on_change _ _ value =
        let inst = create None (after value) in
        instance := Some inst ;
        update (draw inst)
      in
      let () =
        match !instance with
        | Some x -> update (draw x)
        | None -> update [combobox prompt on_change choices]
      in
      let get () =
        match (!instance, prompt) with
        | Some i, _ -> get i
        | None, `Default x -> (create None (after x)).get ()
        | _ -> None
      in
      {get; block = [div]} )

let choose ?(show = true) options =
  let flat = List.concat @@ List.map snd (snd options) in
  let block x =
    if not show then []
    else
      try [b [txt @@ fst @@ List.find (fun (_, v) -> v = x) flat]]
      with _ -> []
  in
  choose_then
    ~inverse:(fun x -> Some x)
    options
    (fun x -> return ~block:(block x) x)

let run ~cl ~button form default f =
  let instance = create default form in
  instance.block @ [br ()]
  @ [ Model.action ~cl
        (fun () -> Option.iter f (instance.get ()))
        [txt button] ]

let autocomplete conv placeholder list =
  F
    (fun init ->
      let init = Option.map conv.Conv.show init in
      let widget, value =
        Widget.Autocomplete.create ?value:init placeholder list
      in
      { block = [widget]
      ; get =
          (fun () ->
            match Conv.parse conv (value ()) with
            | Error _ -> None
            | Ok v -> Some v ) } )
