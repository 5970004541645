open Core

type 'a raw =
  | Simple of 'a
  | Composed of 'a raw list
  | LooseJuxtaposition of 'a raw list
  | TightJuxtaposition of 'a raw list
  | Fuse of 'a raw * 'a raw
[@@deriving yojson]

type 'a t = 'a raw * Bound.bounds [@@deriving yojson]

let show f =
  let rec aux = function
    | Simple x -> f x
    | Composed ls -> String.concat " + " @@ List.map (show true) ls
    | LooseJuxtaposition ls -> String.concat " | " @@ List.map (show true) ls
    | TightJuxtaposition ls ->
        String.concat " || " @@ List.map (show true) ls
    | Fuse (a, b) -> Printf.sprintf "%s + {%s}" (show true a) (show false b)
  and show b x =
    match (b, x) with
    | _, Simple y -> f y
    | false, x -> aux x
    | true, x -> "(" ^ aux x ^ ")"
  in
  aux

let parse_raw p =
  let open Parsing in
  let p' = trim >> p >>| fun x -> Simple x in
  let simple x = trim >> (parens x <|> p') in
  let rec of_list acc keyword f p =
    Parsing.keyword keyword >> simple p
    >>= fun v ->
    of_list (v :: acc) keyword f p <|> return (f (List.rev (v :: acc)))
  in
  let fuse x p =
    keyword "+" >> keyword "{" >> simple p
    >>= fun y -> keyword "}" >> return @@ Fuse (x, y)
  in
  fix (fun parse ->
      simple parse
      >>= fun init ->
      fuse init parse
      <|> of_list [init] "||" (fun l -> TightJuxtaposition l) parse
      <|> of_list [init] "|" (fun l -> LooseJuxtaposition l) parse
      <|> of_list [init] "+" (fun l -> Composed l) parse
      <|> return init )

let parse p = Bound.parse (parse_raw p)

let rec matches local_match c c' =
  match (c, c') with
  | Simple None, _ -> true
  | Simple (Some x), Simple y -> local_match x y
  | Simple _, _ -> false
  | Fuse (a, b), Fuse (a', b') ->
      matches local_match a a' && matches local_match b b'
  | Fuse (_, _), _ -> false
  | LooseJuxtaposition l, LooseJuxtaposition l' ->
      match_in_order local_match l l'
  | LooseJuxtaposition _, _ -> false
  | TightJuxtaposition l, TightJuxtaposition l' ->
      match_in_order local_match l l'
  | TightJuxtaposition _, _ -> false
  | Composed l, Composed l' -> match_commutative local_match l l'
  | Composed _, _ -> false

and match_in_order f l l' =
  match (l, l') with
  | [], [] -> true
  | [Simple None], [] -> true
  | _, [] -> false
  | [], _ -> false
  | Simple None :: l, x :: l' ->
      match_in_order f (Simple None :: l) l'
      || match_in_order f l l'
      || match_in_order f l (x :: l')
  | x :: l, y :: l' -> matches f x y && match_in_order f l l'

and match_commutative f l l' =
  let is_none = List.mem (Simple None) l in
  let subset a b =
    List.for_all (fun x -> List.exists (fun y -> matches f x y) b) a
  in
  subset l l' && ((not is_none) || List.length l = List.length l')

let rec contains x ( = ) = function
  | Simple y -> x = y
  | Composed l | LooseJuxtaposition l | TightJuxtaposition l ->
      List.exists (contains x ( = )) l
  | Fuse (a, b) -> contains x ( = ) a || contains x ( = ) b
