let ( // ) = Filename.concat

let force = function Ok v -> v | Error e -> failwith e

let split c s =
  let get i j = String.sub s i (j - i) in
  let rec aux acc last i =
    if i = String.length s then List.rev (get last i :: acc)
    else if s.[i] = c then aux (get last i :: acc) (i + 1) (i + 1)
    else aux acc last (i + 1)
  in
  aux [] 0 0

let contexts = ref []

let reset_context () = contexts := []

let format f fmt =
  Printf.kprintf
    (fun s -> f @@ String.concat ": " (List.rev (s :: !contexts)))
    fmt

let enter f x fmt =
  let finally () =
    contexts := List.tl !contexts ;
    format (Printf.printf "\r%s%!") ""
  in
  Printf.kprintf
    (fun s ->
      contexts := s :: !contexts ;
      format (Printf.printf "\r%s%!") "" ;
      try
        let v = f x in
        finally () ; v
      with e -> finally () ; raise e )
    fmt

let show_context () = String.concat " > " (List.rev !contexts)

let warning ?nocontext fmt =
  if nocontext = None then
    format (fun s -> Printf.eprintf "\rWarning: %s\n%!" s) fmt
  else Printf.kprintf (fun s -> prerr_endline ("\r" ^ s)) fmt

let fail fmt = format (fun s -> Error s) fmt

type 'a optional = ('a, string) result

let ( >>= ) = Stdlib.Result.bind

let ( >|= ) m f = Stdlib.Result.map f m

let ( let* ) = Stdlib.Result.bind

let return x = Ok x

let is_prefix prefix s =
  String.length prefix <= String.length s
  && String.sub s 0 (String.length prefix) = prefix

let trim s n = String.sub s n (String.length s - n)

let trim_spaces s = Scanf.sscanf s " %[^\n]" (fun s -> s)

let strip_prefix prefixes s =
  let l = List.filter (fun p -> is_prefix p s) prefixes in
  let x =
    List.sort (fun p p' -> compare (String.length p') (String.length p)) l
  in
  match x with [] -> None | p :: _ -> Some (p, trim s (String.length p))

let rec find_map f = function
  | [] -> raise Not_found
  | t :: q -> ( match f t with None -> find_map f q | Some x -> x )

let catch f x = try return (f x) with e -> fail "%s" (Printexc.to_string e)

let catch_ f x = try f x with e -> fail "%s" (Printexc.to_string e)

let sequence l =
  let rec aux acc = function
    | [] -> return (List.rev acc)
    | t :: q -> t >>= fun v -> aux (v :: acc) q
  in
  aux [] l

let sequence_only_successful list =
  List.filter_map
    (function
      | Error e ->
          warning ~nocontext:() "%s" e ;
          None
      | Ok v -> Some v )
    list

let mapi_only_successful_warning f l =
  List.rev @@ snd
  @@ List.fold_left
       (fun (i, l) x ->
         match f i x with
         | Error e ->
             warning ~nocontext:() "%s" e ;
             (i + 1, l)
         | Ok v -> (i + 1, v :: l) )
       (0, []) l

let map_only_successful_warning f l =
  mapi_only_successful_warning (fun _ -> f) l

let report_error = function
  | Ok v -> v
  | Error e ->
      Printf.eprintf "Fatal error: \n%s\n" e ;
      exit 1

let report_warning = function
  | Ok () -> ()
  | Error e -> warning ~nocontext:() "%s" e

let rec findi pred = function
  | [] -> raise Not_found
  | t :: _ when pred t -> 0
  | _ :: q -> 1 + findi pred q

let lines fd =
  let rec loop lines =
    match input_line fd with
    | exception _ -> List.rev lines
    | s -> loop (s :: lines)
  in
  loop []

let contents fd = String.concat "\n" @@ lines fd

let markdown string =
  let fdin, fdout = Unix.open_process "pandoc -f markdown -t html" in
  Printf.fprintf fdout "%s\n" string ;
  close_out fdout ;
  let s = contents fdin in
  ignore @@ Unix.close_process (fdin, fdout) ;
  s

let fix_opt (type a) (f : 'b Lazy.t -> ('b, a) Result.t) =
  let exception F of a in
  let rec (x : 'b Lazy.t) =
    lazy (match f x with Ok v -> v | Error x -> raise (F x))
  in
  try Ok (Lazy.force x) with F x -> Error x

let lift = function Ok v -> Ok v | Error e -> fail "%s" e

let json_of_file convert file =
  try
    enter
      (fun () -> lift @@ convert @@ Yojson.Safe.from_file file)
      () "Parsing %s" file
  with e ->
    fail "While loading json file %s: %s" file (Printexc.to_string e)

let rec take ?(acc = []) n = function
  | [] -> List.rev acc
  | _ when n = 0 -> List.rev acc
  | t :: q -> take ~acc:(t :: acc) (n - 1) q

let js_encode_string string =
  let buf = Buffer.create (5 * String.length string) in
  Printf.bprintf buf "\"" ;
  for i = 0 to String.length string - 1 do
    Printf.bprintf buf "\\\\%03d" (Char.code string.[i])
  done ;
  Printf.bprintf buf "\"" ;
  Buffer.contents buf

let js_var v x = Printf.sprintf "var %s = '%s';" v (js_encode_string x)

let js_decode_string string = Scanf.sscanf string "%S" (fun s -> s)
