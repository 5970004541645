type confidence = Sure | Unsure [@@deriving yojson]

type 'a t = 'a * confidence [@@deriving yojson]

let show f (v, c) = match c with Sure -> f v | Unsure -> f v ^ "?"

let parse p =
  let open Parsing in
  let* x = p in
  prefix [("?", return (x, Unsure)); ("", return (x, Sure))]
